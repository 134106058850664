@font-face {
    font-family: Montserrat-Regular;
    src: url("../fonts/Montserrat-Regular.eot") format("embedded-opentype");
    src: url("../fonts/Montserrat-Regular.woff") format("woff"), url("../fonts/Montserrat-Regular.ttf") format("truetype"), url("../fonts/Montserrat-Regular.svg") format("svg"), url("../fonts/Montserrat-Regular.otf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Montserrat-Light;
    src: url("../fonts/Montserrat-Light.ttf") format("truetype"), url("../fonts/Montserrat-Light.otf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Montserrat-ExtraLight;
    src: url("../fonts/Montserrat-ExtraLight.ttf") format("truetype"), url("../fonts/Montserrat-ExtraLight.ttf") format("opentype"), url("../fonts/Montserrat-ExtraLight.otf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: MyriadPro-BoldCond;
    src: url("../fonts/MyriadProBoldCond.eot") format("embedded-opentype");
    src: url("../fonts/MyriadProBoldCond.woff") format("woff"), url("../fonts/MyriadProBoldCond.ttf") format("truetype"), url("../fonts/MyriadPro-BoldCond.svg") format("svg"), url("../fonts/MyriadPro-BoldCond.otf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Montserrat-Bold;
    src: url("../fonts/Montserrat-Bold.eot") format("embedded-opentype");
    src: url("../fonts/Montserrat-Bold.woff") format("woff"), url("../fonts/Montserrat-Bold.ttf") format("truetype"), url("../fonts/Montserrat-Bold.svg") format("svg"), url("../fonts/Montserrat-Bold.otf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Montserrat-ExtraBold;
    src: url("../fonts/MyriadPro-Bold.eot") format("embedded-opentype");
    src: url("../fonts/MyriadPro-Bold.woff") format("woff"), url("../fonts/Montserrat-ExtraBold.ttf") format("truetype"), url("../fonts/MyriadPro-Bold.svg") format("svg"), url("../fonts/Montserrat-ExtraBold.otf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Montserrat-Black;
    src: url("../fonts/Montserrat-Black.eot") format("embedded-opentype");
    src: url("../fonts/Montserrat-Black.woff") format("woff"), url("../fonts/Montserrat-Black.ttf") format("truetype"), url("../fonts/Montserrat-Black.svg") format("svg"), url("../fonts/Montserrat-Black.otf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Montserrat-SemiBold;
    src: url("../fonts/Montserrat-SemiBold.eot") format("embedded-opentype");
    src: url("../fonts/Montserrat-SemiBold.woff") format("woff"), url("../fonts/Montserrat-SemiBold.ttf") format("truetype"), url("../fonts/Montserrat-SemiBold.svg") format("svg"), url("../fonts/Montserrat-SemiBold.otf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: PlaylistScrip;
    src: url("../fonts/PlaylistScript.eot") format("embedded-opentype");
    src: url("../fonts/PlaylistScript.woff") format("woff"), url("../fonts/PlaylistScript.svg") format("svg"), url("../fonts/PlaylistScript.ttf") format("truetype"), url("../fonts/PlaylistScript.otf");
}

@font-face {
    font-family: CrimsonText-Bold;
    src: url("../fonts/CrimsonText-Bold.eot") format("embedded-opentype");
    src: url("../fonts/CrimsonText-Bold.woff") format("woff"), url("../fonts/CrimsonText-Bold.woff2") format("woff2"), url("../fonts/CrimsonText-Bold.ttf") format("truetype"), url("../fonts/CrimsonText-Bold.svg") format("svg"), url("../fonts/CrimsonText-Bold.otf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Alternate-Gothic-No1-Regular;
    src: url("../fonts/AlternateGothicNo1DRegular.eot") format("embedded-opentype");
    src: url("../fonts/AlternateGothicNo1DRegular.woff2") format("woff2"), url("../fonts/AlternateGothicNo1DRegular.ttf") format("truetype"), url("../fonts/AlternateGothicNo1DRegular.otf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Rubik-Regular;
    src: url("../fonts/rubik/Rubik-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Rubik-SemiBold;
    src: url("../fonts/rubik/Rubik-SemiBold.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: Rubik-Bold;
    src: url("../fonts/rubik/Rubik-Bold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Rubik-Light;
    src: url("../fonts/rubik/Rubik-Light.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: Rubik-Medium;
    src: url("../fonts/rubik/Rubik-Medium.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

:root {
    --blueColor: #080C38;
}
.font-rubik-medium{
    font-family: Rubik-Medium !important;
    font-weight: lighter !important;
}
.navbar-dark .navbar-toggler-icon {
    background-image: url(/static/img/menu_hamburguesa.png);
}

.font-rubik-light {
    font-family: Rubik-Light !important;
}

.font-rubik-regular {
    font-family: Rubik-Regular;
}

.font-rubik-semibold {
    font-family: Rubik-SemiBold;
}

.font-rubik-bold {
    font-family: Rubik-Bold;
}

.labelobj {
    width: 100%;
}

.paypal-button-container {
    width: 60%;
    margin: 0 auto;
}

.btn-default:hover {
    color: #FFF;
    background-color: #70C65C !important;
    border-color: #70C65C !important;
}

.menu_section ul li ul {
    list-style: none;
}

.row-banner-home .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #414042;
    line-height: 35px;
    font-family: 'Rubik-SemiBold';
}

.btn:hover,
.btn:focus,
.btn.focus {
    color: #ffffff !important;
    outline-offset: initial;
    background-color: #70C65C !important;
    border-color: #70C65C !important;
}
.btn-verde-fuerte {
    color: #FFF;
    background-color: #24cc63 !important;
    border-color: #24cc63 !important;
}
.btn-verde-fuerte:hover {
    color: #FFF;
    background-color: #080C38 !important;
    border-color:#080C38 !important;
}
.container-porque-lc .embed-container {
    min-height: 400px !important;
    max-height: 400px !important;
    display: contents;
}

.container-porque-lc .embed-container .video {
    height: 100%;
    width: 100%;
    transform: translateY(0);
    vertical-align: middle;
    position: relative;
}

.titulo-porque-lc {
    font-size: 1.9rem;
}

.select2-container--default .select2-selection--single {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 40px;
}

.contenedor-select .select2-container--default .select2-selection--single {
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 8px;
    height: 40px;
}

.contenedor-select .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #080C38;
    line-height: 35px;
    font-family: Rubik-SemiBold;
}

.select2-container--default:hover .select2-selection--single {
    background-color: #080C38;
    border: 1px solid #080C38;
    color: #ffffff !important;
    font-size: 1rem;
}

.select2-container--default:hover .select2-selection--single .select2-selection__arrow b {
    border-color: #FFFFFF transparent transparent transparent !important;
}

.cont-ti .select2-container--default:hover .select2-selection--single:hover {
    color: #787a8c !important;
}

.form-control {
    height: 40px;
}

.select2-container--default:hover .select2-selection--single:hover .select2-selection__rendered:hover,
.select2-container--default:focus .select2-selection--single:focus .select2-selection__rendered:focus {
    color: #ffffff;
    font-size: 1rem;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: #a0e000;
    color: white;
    font-size: 1rem;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 3px;
    right: 10px;
}

.select2-container--default .select2-results__group {
    background: #080C38;
    color: #fff;
}

#select2-cod_pais_movil-container {
    font-size: 1rem;
}

.select2-results__option--selectable {
    cursor: pointer;
    font-size: 1rem;
}

#cotizar-pago .btn:hover,
#cotizar-pago .btn:focus,
#cotizar-pago .btn.focus {
    color: #080C38 !important;
    outline-offset: initial;
    background-color: #70C65C !important;
    border-color: #70C65C !important;
}

.texto-total-seleccion {
    font-size: 18px;
    font-family: Montserrat-Bold;
    color: #080C38 !important;
    margin-bottom: 0;
}

.marge-buscador {
    padding-top: 10px !important;
}
/*******************************BOTON DE WHATSAPP************************************/
:root {
    --sep: 20px; /* Define the --sep custom property */
     --header: 50px; /* Define the --header custom property */
    --s: 10px; /* Define the --s custom property */
     --peak:  polygon(100% 0, 100% 100%, 0 50%);
}

.icon-chat-whatsapp{
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    background-color: #25D366;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    z-index: 10;
    display: none;
}

.icon-chat-whatsapp:hover {
    transform: scale(1.1);
}

.icon-chat-whatsapp-button img {
    width: 40px;
    height: 40px;
}
.box-chat-whatsapp-header img {
    width: 35px;
    height: 35px;
}
.icon-chat-whatsapp-tooltip {
    position: absolute;
    bottom: 10px;
    background: #fff;
    color: #080C38;
    padding: 4px 12px;
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    font-size: 14px;
    display: none;
    right: 70px;
    max-width: calc(100vw - var(--s) - 45px);
    height: 32px;
}
.icon-chat-whatsapp-tooltip:after {
    content: "";
    display: block;
    position: absolute;
    top: 10px;
    right: -6px;
    border: 8px solid transparent;
    border-width: 6px 0 6px 8px;
    border-left-color: #fff;
}

.box-chat-whatsapp{
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 5px;
    right: 0;
    z-index: 1;
    width: calc(100vw - var(--sep) * 2);
    max-width: 400px;
    max-height:100%;
    border-radius: 20px;
    background: #FFFFFF;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .5);
    text-align: left;
    overflow: hidden;
    opacity: 1;
    padding: 0;
    display: none;
}
.box-chat-whatsapp-close{
    --size: 25px;
    position: absolute;
    top: calc(50% - var(--size) / 2);
    right: 15px;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.4) url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 24 24'%3E%3Cpath d='M24 2.4 21.6 0 12 9.6 2.4 0 0 2.4 9.6 12 0 21.6 2.4 24l9.6-9.6 9.6 9.6 2.4-2.4-9.6-9.6z'/%3E%3C/svg%3E") 50% no-repeat;
    background-size: 12px;
    cursor: pointer;
    transition: background-color .3s ease-out;
}
.icon-chat-whatsapp-tooltip-hover   {
    display: block;
}
.box-chat-whatsapp-header {
    display: flex;
    flex-flow: row;
    align-items: center;
    position: relative;
    flex-shrink: 0;
    height: var(--header);
    min-height: 50px;
    padding: 1.8rem 1rem 1.8rem 1rem;
    margin: 0;
     background: #25D366;
}
.box-chat-whatsapp-scroll {
    overflow: hidden;
    overscroll-behavior-y: contain;
    -webkit-overflow-scrolling: touch;
}
.box-chat-whatsapp-content {
    width: calc(100% + 4px);
    padding: 20px calc(var(--s) + 16px);
}
.box-chat-whatsapp-message {
    position: relative;
    min-height: 56px;
    padding: 15px 20px;
    border-radius: 26px;
    background: #fff;
    word-break: break-word;
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, .3));
    transform: translateZ(0);
}
.box-chat-whatsapp-message:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 18px;
    left: -15px;
    width: 17px;
    height: 25px;
    background: inherit;
    clip-path: var(--peak);
}
.box-chat-whatsapp-option {
    font-size: 13px;
    line-height: 1.33;
}
.chat-whatsapp-button{
    display: flex;
    position: relative;
    z-index: 2;
    bottom: 8px;
    right: 8px;
    height: 50px;
    min-width:165px;
    background: #25d366;
    border-radius: 30px;
    box-shadow: 1px 6px 24px 0 rgba(7, 94, 84, .24);
    cursor: pointer;
    transition: background-color .2s linear;
    float: right;
}
.chat-whatsapp-button:hover {
    transform: scale(1.1);
}
.chat-whatsapp-button  .sendtext {
    padding:.8rem 0rem .8rem 1.2rem;
    max-width: min(400px - 16px - var(--s), 100vw - 16px - var(--s) - 2* var(--sep));
    opacity: 1;
    transition: max-width .2s linear, opacity .4s ease-out .2s;
    float: left;
}
.chat-whatsapp-button-img{
    padding:.8rem 0rem .8rem 1.2rem;
}

/****************************CSS BANNER NUEVO**********************************************/
.subtitulo-boton{
    font-size: 1.4rem;
}
.contenidoBannes {
    position: absolute;
    top: 0;
    width: 100%;
    display: table;
}

#bannersEscritorio,
#bannersEscritorio .carousel-inner,
#bannersEscritorio .carousel-inner .carousel-item,
#bannersEscritorio .embed-container,
#bannersEscritorio .contenidoBannes {
    min-height: 650px !important;
    max-height: 650px !important;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.subtitulo-h1-banner {
    font-size: 1.8rem;
    font-weight: 400;
    font-feature-settings: 'liga' 1;
    line-height: 1.5;
    text-rendering: optimizeLegibility;
    text-shadow: 1px 1px 6px rgb(78 78 90 / 78%);
}

.titulo-h1-banner {
    font-size: 3rem;
    line-height: 1.2;
    text-shadow: 1px 1px 6px rgb(8 8 12 / 50%);
    margin: 0;
    text-align: left;
}

.row-banner-home {
    background: url("/static/img/home/fondo_buscador_home.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 90px;
}

.titulo-buscador-banner {
    font-size: 2.8rem;
    margin: 0;
    /* text-shadow: 1px 1px 6px rgb(0 0 0 / 50%);*/
}

.subtitulo-buscador-banner {
    font-size: 1.5rem;
    margin: 0;
}

.boton-buscador-banner {
    width: 100%;
    height: 40px !important;
    border-radius: 20px;
}

.buscador-banner .select2-container--default,
.buscador-banner .select2-selection--single {
    height: 40px;
    border-radius: 0;
}

.buscador-banner .select2-container--default:hover .select2-selection--single:hover {
    font-size: 13px;
    background-color: #ffffff;
    color: #414042 !important;
    border-color: #ffffff;
}

.subtitulo-movil {
    display: none;
}


/*.buscador-banner.buscador-banner .select2-container--default, .buscador-banner .select2-selection--single[aria-controls="select2-id_categorias-container"]{
    border-radius: 20px 0 0 20px !important;
}*/

.buscador-banner .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #414042 !important;
    padding: 5px;
}

.buscador-banner .select2-container--default:hover .select2-selection--single:hover .select2-selection__rendered:hover,
.buscador-banner .select2-container--default:focus .select2-selection--single:focus .select2-selection__rendered:focus {
    color: #414042 !important;
}

.buscador-banner .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 30px;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
}

.buscador-banner #formulario {
    padding: 10px;
}

.titulos-h1 {
    font-size: 2.2em
}

.titulos-h2 {
    font-size: 2em;
}

.titulo-seo-h2 {
    font-size: 20px;
    line-height: 1.5;
    text-align: justify;
    font-weight: bold;
}

#menu_toggle:hover {
    color: #A0E000 !important;
}

span.flatpickr-day,
span.flatpickr-day.prevMonthDay,
span.flatpickr-day.nextMonthDay {
    border: transparent !important;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
    background: #080C38 !important;
    border-color: #080C38 !important;
    border-radius: 20px !important;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
    border-radius: 20px !important;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
    border-color: #a0e03f !important;
    background: #a0e03f !important;
    color: #fff !important;
    border-radius: 20px !important;
}

.comillas {
    width: 65px;
}

.nombreUser {
    float: left;
    margin: 0px 17px;
    position: relative;
    top: 5px;
}

.mundo-header {
    margin-right: 7px;
    width: 25px;
}

.li-planificador {
    float: left;
    margin: 0px 17px 0 0;
}

.star-rating .fa-star,
svg.fa-star {
    line-height: 32px;
    font-size: 1.2em;
    color: #A0E000;
    cursor: pointer;
    padding: 6px;
}

#seo-h1-h2 {
    width: 60%;
    margin: 50px auto;
    text-align: center;
    list-style: none;
    font-family: Montserrat-Light;
}

#seo-h1-h2 li {
    font-size: 11px;
    margin-bottom: 0;
    line-height: 1.5;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    font-family: Montserrat-Light;
}

.subtitulo-pag {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 0;
    margin-top: 0;
    text-align: center;
    font-family: Montserrat-Light;
}

.verdeclarito {
    background-color: #F5FCE5;
    border-color: #F5FCE5;
}

.ui-pnotify .alert-danger {
    color: #4F0800;
    background-color: #f2dede;
    border-color: #4F0800;
}

.alert-error {
    color: #f30000;
    background-color: #ffeded;
    border-color: #ffeded;
}

.ui-pnotify .alert-success {
    background: #dff0d8 !important;
    color: #2f6330;
    border-radius: 8px;
    border-color: #dff0d8 !important;
    /*background: #ffffff !important;
    color: #414042;
    border-radius: 8px;
    border-color: #414042 !important;*/
}

.video-banner {
    height: 590px;
}

.ui-pnotify .alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}

#contenedor-menu {
    background: #F6F6F6;
}

#contenedor-menu .dropdown-menu,
.menu-table-movil .dropdown-menu {
    min-width: 220px !important;
}

.botonera-wrap {
    position: fixed;
    bottom: 0;
    z-index: 10;
    width: auto;
    height: 12rem;
    -webkit-transition: bottom .3s ease;
    transition: bottom .3s ease;
    right: 0;
}

.botonera-wrap .contnedorBar {
    background-color: #f7f7f7;
    padding: 10px 15px;
    border-radius: 4px 0 0px 0;
}

.botonera-wrap .contnedorBar .items {
    padding: 0;
    margin: 0;
    height: 100%;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.botonera-wrap .contnedorBar .items li {
    height: 100%;
    padding: 4px;
    background-size: 1.75rem 1.75rem;
    background-position: center 1rem;
    text-align: center;
    margin: 0 auto;
    /*cursor: pointer;*/
    border-right: 1px solid #ddd;
}

.pasos {
    float: left;
    margin-right: 5px;
    font-family: 'Montserrat-Bold';
    font-size: 1.6em;
}

.img-pasos {
    float: left;
    margin-top: 3px;
}

.cont-pas {
    position: relative;
    left: 22px;
}

.botonera-wrap .contnedorBar .items li a,
.botonera-wrap .contnedorBar .items li button {
    letter-spacing: 1.3px;
    width: 9rem;
    text-transform: uppercase;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: color .3s ease;
    transition: color .3s ease;
    cursor: pointer;
    padding-bottom: .55rem;
    padding-top: .55rem;
    text-decoration: none;
    position: relative;
    font-size: 1rem;
}

.content-buscador-home #formulario {
    margin: 58px auto;
    float: left;
    padding: 40px 30px;
    border-radius: 8px;
}

.cont-busca-home {
    margin: 0 auto;
    width: 50%;
}

.cot-video-canada {
    margin-top: 3%;
    margin-bottom: 6%;
}


/*************************BASE USUARIOS*****************#E3E4ED *************/

.contenedorFondo {
    background: #E3E4ED url(/static/img/registro_usuarios/base_beige.png);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
}

.contenedorForm {
    border-radius: 8px;
    margin-top: 3rem;
    margin-bottom: 3rem;
    box-shadow: -4px 2px 17px 9px #2b346614;
    z-index: 0;
    background: #ffffff;
}

.bootstrap-select .dropdown-toggle:focus {
    outline: none !important;
    color: #FFF !important;
    background-color: #080C38 !important;
}

.redes {
    height: 35px;
    width: 35px;
    margin: 15px 5px;
    cursor: pointer;
    display: inline;
}


/***************************************************************************/

.ui-pnotify .alert-info,
.ui-pnotify .brighttheme-info {
    color: #012831;
    background-color: #daf3f9;
    border: 1px solid #daf3f9;
}

.ui-pnotify .brighttheme {
    color: #012831;
    background-color: #daf3f9;
    border: 1px solid #daf3f9;
}

.ui-pnotify-icon,
.ui-pnotify-icon span {
    display: block;
    float: left;
    margin-right: .5em;
    border-radius: 32px;
    width: 20px;
    height: 20px;
    padding: 2px 4px;
    border-color: #012831 !important;
}

.paypal-button.paypal-button-color-black {
    background: #080C38 !important;
    color: #fff;
}

.review-home .star-rating .fa-star {
    color: #F4E202;
    font-size: 1.8em;
}

.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus {
    background-color: #70C65C !important;
    border-color: #70C65C !important;
    color: #FFFFFF;
}

.nav-azul {
    min-height: 100px;
    z-index: 30;
    width: 100%;
    background: #A0E000;
    border-color: #A0E000;
    margin-bottom: 0;
    border-radius: 0;
}

.logo_lc {
    position: relative;
}

.header-verde {
    min-height: 100px;
    z-index: 30;
    width: 100%;
    background: #A0E000;
    border-color: #A0E000;
    margin-bottom: 0;
    border-radius: 0;
}

.triangulo-left {
    margin-top: -69px;
    padding-left: 0 !important;
    margin-left: -15px;
    position: relative;
    width: 52%;
    height: 70px;
    clip-path: polygon(0% 100%, 100% 100%, 0% 0%);
    float: left;
    background-color: #FFFFFF;
}

.triangulo-rigth {
    margin-top: -69px;
    padding-left: 0 !important;
    margin-right: -15px;
    position: relative;
    width: 52%;
    height: 70px;
    clip-path: polygon(0% 100%, 100% 100%, 100% 0%);
    float: right;
    background-color: #FFFFFF;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #FFFFFF;
    width: 25px;
    height: 4px;
}

.pies li > a {
    padding-left: 0 !important;
}

.yvideo video {
    width: 100% !important;
}

.border-radius {
    border-radius: 4px;
}

#html5-watermark {
    position: absolute !important;
    top: auto !important;
    left: auto !important;
    right: 10px !important;
    bottom: 56px !important;
    opacity: 0;
}

.container-banner-promocional,
.contenedor-select {
    width: 100%;
    padding-right: var(--bs-gutter-x, .75rem);
    padding-left: var(--bs-gutter-x, .75rem);
    margin-right: auto;
    margin-left: auto;
}


/*.bootstrap-select.btn-group .dropdown-toggle .filter-option{
    text-align: center!important
}*/

.corazonVerde {
    cursor: pointer;
    -webkit-filter: drop-shadow(1px 1px 0px #333) !important;
    filter: drop-shadow(1px 0px 0px #595858) !important;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
    background-color: #f6fce5;
}

.input-group-addon {
    padding: 3px 9px !important;
}

.btn-default {
    font-family: Rubik-SemiBold;
    font-size: 1rem !important;
    color: #FFF !important;
    border-radius: 0.3rem;
}

.btn-regular {
    font-family: Rubik-Regular;
    text-transform: uppercase;
}

button.ui-pnotify-action-button.btn.btn-default {
    background: #080C38;
}

.boton-solic {
    font-size: 12pt !important;
}

.future {
    position: absolute;
    left: 5em;
    width: 240px;
    font-size: 1.8rem;
    top: -46px;
    font-weight: normal;
}

#footer .future2 {
    font-size: 1.1rem;
}

.imgtrian {
    width: 110px;
    margin: auto;
    position: absolute;
    top: -40px;
    z-index: 3;
}

.dropdown-backdrop {
    position: static;
}


/*.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{

}*/


/************SUB MENU**********/

.dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px 6px;
    border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
    display: block;
}

.dropup .dropdown-submenu > .dropdown-menu {
    top: auto;
    bottom: 0;
    margin-top: 0;
    margin-bottom: -2px;
    -webkit-border-radius: 5px 5px 5px 0;
    -moz-border-radius: 5px 5px 5px 0;
    border-radius: 5px 5px 5px 0;
}
.base-registrate{
    box-shadow: 5px 5px 10px rgba(0, 0, 0, .1);
    background:#080C38;
    color:#FFFFFF !important;
    border-radius: .5rem;
}
.dropdown-submenu > a:after {
    display: block;
    float: right;
    width: 0;
    height: 0;
    margin-top: 5px;
    margin-right: -10px;
    border-color: transparent;
    border-left-color: #414042;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    content: " ";
}

.dropdown-submenu:hover > a:after {
    border-left-color: #414042;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
}

#menu_principal {
    top: 5px;
}

#menu_principal .navbar-nav > li > a:hover {
    border-bottom: 1px solid #080C38;
}

#id_menuheaderP > li > a:hover {
    border-bottom: 1px solid #080C38;
}


/************************************/

.quitar {
    margin: 0;
}

#previaCarpeta-movil {
    position: absolute;
    background: #fff;
    right: 177px;
    top: 73px;
    z-index: 1;
}

.titulos_principales {
    font-size: 2.5em;
}

p {
    word-wrap: break-word;
}

.review {
    height: 110px;
    margin-right: 25px;
}

.cont-oficinas-paises::-webkit-scrollbar-corner {
    background-color: #909090;
}

.cont-oficinas-paises::-webkit-scrollbar-button {
    background-color: rgba(146, 148, 151, 0.46);
}

.cont-oficinas-paises::-webkit-scrollbar {
    width: 9px;
}

.cont-oficinas-paises::-webkit-scrollbar-track {
    background-color: #f4f4f4;
}

.cont-oficinas-paises::-webkit-scrollbar-thumb {
    background-color: rgba(187, 189, 194, 0.2);
}

.cont-oficinas-paises::-moz-scrollbar-corner {
    background-color: #909090;
}

.cont-oficinas-paises::-moz-scrollbar-button {
    background-color: rgba(146, 148, 151, 0.46);
}

.cont-oficinas-paises::-moz-scrollbar {
    width: 9px;
}

.cont-oficinas-paises::-moz-scrollbar-track {
    background-color: #f4f4f4;
}

.cont-oficinas-paises::-moz-scrollbar-thumb {
    background-color: rgba(187, 189, 194, 0.2);
}

.cont-oficinas-paises::-ms-scrollbar-button {
    background-color: rgba(146, 148, 151, 0.46);
}

.cont-oficinas-paises::-ms-scrollbar {
    width: 9px;
}

.cont-oficinas-paises::-ms-scrollbar-track {
    background-color: #f4f4f4;
}

.cont-oficinas-paises::-ms-scrollbar-thumb {
    background-color: rgba(187, 189, 194, 0.2);
}

.pie-ult {
    color: #FFF !important;
    padding: 12px;
    margin: 0 auto;
    margin-right: 7px;
    vertical-align: middle;
    transform: translateY(-50%);
    position: relative;
    top: 50%;
    font-size: 14pt;
}

.tit {
    /* font-family: Montserrat-SemiBold; */
    font-size: 1.8em;
    width: 450px;
    max-width: 100%;
    /* margin: 0 auto; */
    /* color: #fff; */
    position: relative;
    vertical-align: middle;
    padding: 30px 10px;
    line-height: 1.2;
    /* text-shadow: 1px 1px 1px #000; */
    /* line-height: 0.9 !important; */
    /* opacity: 0.9; */
    /* text-shadow: 0px 0px 0px #fff; */
    /* margin-bottom: 20px; */
    margin-top: 0px;
    background: #ffffff;
    /* opacity: 0.8; */
    /* border: 4px solid #fff; */
    left: 22%;
    font-weight: bold;
    border-radius: 4px;
    height: 400px;
    top: 8px;
}

.tit span {
    margin-bottom: 10px !important;
}

.icon-redes-social {
    font-size: .4em !important;
    width: 28px;
    position: absolute;
    top: 13px;
    left: 6px;
}

.cont-redes-social {
    font-size: 3.5em;
    color: #080C38 !important;
}

.col-social #ulRedesFooter li {
    /*background:#080C38;*/
    border-radius: 100px;
    height: 40px;
    width: 40px;
    float: left;
    margin: 5px;
    display: inline-block;
}

.col-social #ulRedesFooter {
    list-style: none;
    margin-top: 20px;
    display: inline-block;
    padding: 0;
}

#ulRedesFooter i,
#ulRedesFooter svg {
    font-size: 1.8em;
    color: #A0E000;
}

#ulRedesFooter i:hover,
#ulRedesFooter svg:hover {
    color: #A0E000;
}

#espacioBlanco .navbar-default .navbar-toggle:hover,
#espacioBlanco .navbar-default .navbar-toggle:focus {
    background-color: #080C38;
}

.fuenteSemiBold {
    font-family: Montserrat-SemiBold;
}

.texto-para-seo {
    margin: 3% 0 3% 0;
}

.subtit {
    font-size: .5em;
    font-family: Montserrat-SemiBold;
    line-height: 1.6;
    margin: 8px auto;
}

.eliminar-todas {
    font-size: 16px;
    font-family: Montserrat-SemiBold;
    margin-top: 18px;
    color: #080C38;
    cursor: pointer;
}

.listSedeimg {
    margin: auto;
    max-height: 250px;
    min-height: 250px;
    max-width: 100%;
}

body {
    color: #414042;
    font-family: Rubik-Regular;
    scrollbar-face-color: #bad032;
    font-size: 1rem;
}

.header-verde .navbar-default .navbar-nav > li > a,
.header-verde .nav-link {
    color: #080C38;
    font-size: 1.1rem;
    font-family: Rubik-SemiBold;
}

#menu-principal.nav-link:focus,
#menu-principal.nav-link:hover {
    color: #080C38;
    border-bottom: 3px solid #080C38;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
    background-color: transparent !important;
    border-bottom: 3px solid #A0E000;
}

.titular {
    font-family: Montserrat-Bold;
}

.titular-SemiBold {
    font-family: Montserrat-SemiBold;
}

.contenedores {
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 5%;
}

#menudos .navbar-default .navbar-nav > li > a {
    color: #A0E000;
}

.gl-star-rating[data-star-rating] {
    position: relative;
}

.texto-cont-scollo {
    font-weight: bold;
    margin-bottom: 0;
    padding: 10px 0;
    text-align: center;
}

.verde {
    background-color: #A0E000 !important;
    border-color: #A0E000 !important;
}

.verde-fuerte {
    background-color: #00C853 !important;
    border-color: #00C853 !important;
}

.pastel-claro {
    background-color: #F9F8F2 !important;
    border-color: #F9F8F2 !important;
}

.pastel-claro2 {
    background-color: #f6f5ef !important;
    border-color: #f6f5ef !important;
}

.pastel-oscuro {
    background-color: #E8E5DA !important;
    border-color: #E8E5DA !important;
}

.verde-btn {
    background-color: #70C65C !important;
    border-color: #70C65C !important;
    outline: transparent !important;
    border-radius: 0.4rem;
}

.verde-btn:hover,
.verde-btn:focus {
    background-color: #080C38 !important;
    border-color: #080C38 !important;
}

.texto-verde-btn {
    color: #70C65C !important;
}

.texto-verde-fuerte {
    color: #00C853 !important;
}

.azul {
    background-color: #080C38 !important;
    border-color: #080C38 !important;
}

.azul-rey {
    background-color: #01295F !important;
    border-color: #01295F !important;
}

.card-destinos {
    box-shadow: 2px 2px 10px 2px #a5a5a578;
}

.card-destinos .img-destinos {
    border-radius: 0.5rem 0.5rem 0 0;
}

.gris {
    background-color: #F7F3F3 !important;
    border-color: #F7F3F3 !important;
}

.gris-oscuro {
    background-color: #E5E5E5 !important;
    border-color: #E5E5E5 !important;
}


/*.gris{background-color: #f2f2f2 !important; border-color: #f2f2f2 !important;}*/

.gris-claro {
    background-color: #f0f0f0 !important;
    border-color: #f0f0f0 !important;
}

.amarillo {
    background-color: #F4E303 !important;
    border-color: #F4E303 !important;
}

.naranja {
    background-color: #F75C03 !important;
    border-color: #F75C03 !important;
}

.blanco {
    background-color: #fff !important;
    border-color: #fff !important;
}

.texto-blanco {
    color: #fff !important;
}

.texto-verde {
    color: #A0E000 !important;
}

.texto-verde-fuerte {
    color: #00C853 !important;
}

.texto-azul {
    color: #080C38 !important;
}

.texto-azul-rey {
    color: #01295F !important;
}

.texto-purpura {
    color: #6591c9 !important;
}

.texto-gris {
    color: #414042 !important;
}

.texto-gris-medio {
    color: #6E7082 !important;
}

.texto-gris-claro {
    color: #f0f0f0 !important;
}

.texto-amarillo {
    color: #F4E303 !important;
}

.texto-naranja {
    color: #F75C03 !important;
}

.texto-rojo {
    color: #BC0A0A !important;
}

.btn {
    padding: 7px 12px;
}

.review-user,
.review-google,
.review-faceboock {
    font-size: 2.1rem;
}

.texto-review-user {
    font-size: .85rem;
}

.texto-review-google {
    font-size: .85rem;
    position: relative;
    bottom: 9px;
}

.texto-review-faceboock {
    font-size: .85rem;
    position: relative;
    bottom: 9px;
}

.incono-mas {
    font-size: 1.5rem;
    float: left;
    position: relative;
    top: 12px;
    margin-right: 0;
    left: 7px;
}

.container-review {
    margin-top: 10px;
}

.btn-buscar:hover {
    color: #FFFFFF;
    background-color: #70C65C !important;
    border-color: #70C65C !important;
}

.btn-default:active:hover {
    color: #000;
    background-color: #080C38;
    border-color: #080C38;
}

.btn-buscador {
    color: #FFF;
    background-color: #080C38;
    border-color: #080C38;
    height: 34px;
}

.btn-buscador:hover {
    background-color: #70C65C !important;
    border-color: #70C65C !important;
}

.bootstrap-select button {
    color: #414042 !important;
    background-color: #ffffff;
    border-color: #ccc;
}

.bootstrap-select button:active {
    color: #FFFFFF !important;
}

#links > li > a {
    color: #FFF !important;
    font-weight: bold;
}

.botonMenuREsponsivo {
    float: right;
}

.como {
    /* position: relative; */
    /* margin: 0 auto; */
    width: 100%;
    color: #FFFFFF;
    font-weight: bold;
    /* text-shadow: 2px 1px 10px #000; */
    /* left: 0; */
    z-index: 18;
}

.enlaces-rapidos {
    text-decoration: underline;
    font-size: .6em;
    margin: 15px 0;
}

.banners .carousel-indicators {
    z-index: 0;
}

.banners,
.banners .carousel-inner {
    min-height: 670px;
    max-height: 670px;
}

.banners .carousel-inner .item {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 670px;
    height: 670px;
}

.img_banners {
    max-width: 100%;
    width: 100%;
    min-height: 650px !important;
    max-height: 650px !important;
}

#VideoB {
    cursor: pointer;
    -webkit-filter: drop-shadow(1px 0px 1px #333);
    filter: drop-shadow(1px 0px 1px #333);
    width: 40px;
    z-index: 3;
}

.content-banner-promocion {
    margin: 4% 0;
}

.row-text-bannerP {
    display: flex;
    align-items: center;
}

.col-text-bannerP > .row:not(.row-text-bannerP) {
    padding: 0.6rem 0 !important;
}

.subtituloP {
    line-height: 1.3;
    font-size: 1.05rem;
    margin: 0;
    color: #6E7082;
}

.boton-banner-promo {
    width: 200px;
    color: #FFFFFF;
}

.a-enlace-banner {
    float: left;
    text-decoration: underline !important;
}

.rowBannerP {
    display: flex;
    align-items: center;
    box-shadow: 2px 2px 10px 2px #a5a5a5;
    /*outline: 1px solid #DDDDDD;*/
}

.favorito-item {
    position: absolute;
    z-index: 9;
    padding: 10px;
    top: 0;
    float: right;
    right: 8px;
    width: 52px;
}

#compartir .glyphicon-share {
    font-size: 1.5em;
    color: #080C38;
}

#compartir .glyphicon-share:hover {
    color: #A0E000 !important;
}

.fa-heart {
    color: #080C38;
}

.fa-heart:hover {
    color: #A0E000 !important;
}

#formulario_solicitud .campo button {
    background: #f0f0f0;
    border: 1px solid #f0f0f0;
}

#formulario_solicitud .campo button:focus {
    color: #414042 !important;
    border: 1px solid #ccc !important;
}

#formulario_solicitud .campo button:hover {
    color: #FFF !important;
    background: #080C38;
    border: 1px solid #080C38;
}

.img-testominio {
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    border-radius: 3rem;
    height: 80px;
    width: 80px;
}

.campo button {
    color: #414042 !important;
    background-color: #ffffff;
    border-color: #ccc;
}

.titulos-principal-testimonio {
    box-shadow: 2px 10px 10px 2px #a5a5a540;
    padding: 3rem 1rem 1rem 1rem !important;
    position: relative;
    right: 89px;
    top: 120px;
    z-index: 100;
    background: #ffffff;
    border-radius: 1rem;
}

.container-testimonios .icon-inside-globo {
    max-width: 3.5rem;
    position: absolute;
    margin-left: -61px;
    margin-top: 10px;
}

.container-testimonios .texto-titulo-testimonio1 {
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
    font-weight: 600;
    line-height: 1.5;
}

.container-testimonios .texto-titulo-testimonio2 {
    font-size: 1.8rem;
}

.container-testimonios .texto-titulo-testimonio3 {
    font-size: 2.8rem;
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #fff;
    border-radius: .50rem;
    margin: 0 auto;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
}

.campo button:hover,
.campo button:active {
    color: #ffffff !important;
    background: #333;
    border: 1px solid #333;
}

.btn-buscar {
    color: #FFF;
    background-color: #080C38;
    border-color: #080C38;
}

.log768 {
    width: 114px !important;
    position: relative;
    top: -14px;
}

.seccion-descripcion {
    margin-left: -6%;
}

#notificacion_contador {
    background: #080C38;
    color: #ffffff;
    font-weight: bold;
    border-radius: 35px;
    -moz-border-radius: 35px;
    -webkit-border-radius: 35px;
    position: absolute;
    z-index: 100;
    font-family: Montserrat-SemiBold;
    font-size: .8rem;
    width: 20px;
    height: 20px;
    right: 15px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gl-star-rating[data-star-rating] {
    position: relative;
}

.gl-star-rating[data-star-rating] .gl-star-rating-stars > span {
    left: 2px;
    top: 4px;
}

.gl-star-rating[data-star-rating] .gl-star-rating-stars {
    height: initial !important;
}


/*fin notificacioens*/

.nav2 {
    background-color: #FFF;
    color: #8A8A8A;
    font-weight: bold;
    margin-bottom: 0px;
    border-color: transparent;
}

#bs-content-row-navbar-collapse-5 .navbar-default .navbar-nav > .open > a,
#bs-content-row-navbar-collapse-5 .navbar-default .navbar-nav > .open > a:hover,
#bs-content-row-navbar-collapse-5 .navbar-default .navbar-nav > .open > a:focus {
    color: #fff;
    background-color: #080C38 !important;
}

.nav2 > ul[aria-expanded=false] {
    display: none;
}

.container-menu-movil {
    top: 70px;
    background: #fff;
    right: 0;
    z-index: 11;
}

#search .navbar-default .navbar-nav > .open > a,
#search.navbar-default .navbar-nav > .open > a:hover,
#search .navbar-default .navbar-nav > .open > a:focus {
    color: #414042;
    background-color: #00C853;
}

.btn-default:visited,
.btn-default:hover,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
    color: #000;
    background-color: #080C38;
    border-color: #080C38;
}

.open > .dropdown-toggle.btn-default:hover {
    color: #fff;
    background-color: #70C65C;
    border-color: #70C65C;
}

.top-alto {
    padding-top: 130px;
}

.top-pequeno {
    padding: 5em 0.9375em 0 14.375em;
    float: left;
    width: 100%;
}

.glyphicon-globe {
    margin-left: 16px !important;
    font-size: 17px;
}


/*#paisesOficinas li{

}*/

.fa-stack-1x {
    line-height: 27px;
    font-size: 14px;
}

.dropdown-menu {
    z-index: 10 !important;
}

.menuHeader .navbar-default .navbar-collapse,
.menuHeader .navbar-default .navbar-form {
    border-color: #A0E000 !important;
    background: #A0E000 !important;
}

.pt-6 {
    padding-top: 0;
    padding-bottom: 3rem;
}

.card-noticias {
    background-color: #F6FBE4;
    border: 1px solid #F6FBE4;
    height: 760px;
    box-shadow: 2px 2px 10px 2px #a5a5a540;
}

.card-noticias ul {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 1.5;
}

.card-noticias .card-footer {
    padding: 0;
    background-color: transparent;
    border-top: none;
    position: absolute;
    bottom: 40px;
    width: 80%;
}

.fecha-publicacion-noticia {
    padding: 1.25rem;
}

.card-noticias .card-body {
    padding: 0 1.25rem;
}

.card-noticias .card-title {
    font-size: 1.1rem;
}

.fecha-publicacion-noticia .svg-inline--fa.fa-w-16 {
    width: 1em;
    font-size: 1.2rem;
    text-align: left;
    float: left;
    margin-right: 10px;
    position: relative;
    top: 2px;
}

.container-testimonios .lado-izquierdo-testimonios {
    height: 700px;
    background: url("/static/img/home/IMAGEN-CLIENTES-800X1200PX.jpg");
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
    border-radius: 1rem;
    margin: 50px 0px 20px 0px;
}

#paises .img-destinos {
    height: 250px;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
}

#noticias .img-noticias {
    height: 250px;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    border-radius: 0.6rem;
    margin: 20px;
}

.container-fluid-review {
    margin-top: 9rem !important;
}

.container-paises {
    background: url(/static/img/home/fondo_mapa1900.png);
    background-position: right;
    height: auto;
    background-repeat: no-repeat;
}

.container-paises .texto-titulo-destino {
    width: 12rem;
    font-size: 2.5rem;
}

.titulo-destino {
    font-size: 3rem;
}

.container-noticias .iconos-titulo {
    float: right;
}

.container-paises .texto-titulo-destino2 {
    width: 12rem;
    font-size: 3rem;
}

.container-paises .card-texto-destinos {
    font-size: 1.4rem;
    font-weight: bold;
}

.container-paises .pt-6 {
    padding-top: 5rem !important;
}
.container-principal-destinos {
}

.container-porque-lc {
    border-radius: 0.5rem;
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
}

.container-principal-porque-elegirnos {
    background: url(/static/img/home/BANNER-DE-1902x658.jpg);
    background-position: center;
    background-size: cover;
    height: 648px;
    background-repeat: no-repeat;
    margin-bottom: -1px;
}

.titulo-elegisrnos1 {
    letter-spacing: 1.5px;
    font-size: 1.3rem;
}

.titulo-elegisrnos2 {
    letter-spacing: 1.5px;
    font-size: 1.8rem;
    margin-top: 5px !important;
}

.titulo-elegisrnos3 {
    font-size: 2.8rem;
    letter-spacing: 4px;
    margin-top: 5px !important;
}

.py-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
}

.descripcion-elegisrnos {
    font-size: 1.3rem;
    line-height: 1.5;
    margin-top: 20px;
    margin-bottom: 30px;
}

.fila-principal {
    padding-left: 60px !important;
}

.container-porque-lc .icon-inside-globo {
    position: relative;
    margin-left: -30px;
    height: 100px;
}

.container-porque-lc .py-5 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
}


/***********************CSS PREGUNTAS FRECUENTES**************/

.close {
    position: absolute;
    right: 0;
    border: navajowhite;
    background: transparent;
    font-size: 1.2rem;
}

#preguntas-frecuentes .open-btn,
#preguntas-frecuentes .close-btn {
    right: 12px !important;
}

#preguntas-frecuentes .accordion-panel {
    margin: 10px 50px;
    max-width: 100%;
}

#preguntas-frecuentes .accordion-panel .accordion {
    padding-top: 30px;
}

.subTitulos {
    font-size: 18pt;
}

#preguntas-frecuentes .accordion-panel .accordion dt {
    display: block;
    padding: 15px 65px 15px 25px;
    background: #f2f2f2;
    color: #555;
    cursor: pointer;
    position: relative;
    user-select: none;
    text-align: justify;
}

#preguntas-frecuentes .accordion-panel .accordion dd {
    height: 0;
    overflow: hidden;
    transition: height .35s ease-out;
    margin-left: 0;
    margin-bottom: 5px;
    background: #f7f7f7;
}

#preguntas-frecuentes .accordion-panel .accordion .content {
    padding: 25px;
    overflow: auto;
    font-weight: normal;
}

.accordion-panel .accordion .content p {
    text-align: justify;
}

#preguntas-frecuentes .accordion-panel .plus-icon {
    display: inline-block;
    width: 15px;
    height: 15px;
    position: relative;
    transition: transform 0.35s ease-out;
    position: absolute;
    right: 35px;
    top: 58%;
    margin-top: -12px;
}

#preguntas-frecuentes .accordion-panel .plus-icon:before,
#preguntas-frecuentes .accordion-panel .plus-icon:after {
    content: '';
    background: #A0E000;
    position: absolute;
    text-shadow: 1px 1px 13px #000;
}

#preguntas-frecuentes .accordion-panel .plus-icon:before {
    width: 2px;
    height: 15px;
    margin-left: -0.5px;
    left: 50%;
}

.logo-previa-carpeta {
    width: 40px;
}

.arrow-after {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    font-size: 0;
    line-height: 0;
    top: -6px;
    position: absolute;
    right: 20px;
}

.arrow-before {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ddd;
    font-size: 0;
    line-height: 0;
    top: -6px;
    position: absolute;
    right: 21px;
}

#preguntas-frecuentes .accordion-panel .plus-icon:after {
    width: 15px;
    height: 2px;
    margin-top: -0.5px;
    top: 50%;
    opacity: 1;
    transition: opacity 0.35s ease-out;
}

.bandera-pais {
    width: 25px;
}

#preguntas-frecuentes .accordion-panel .is-open .plus-icon {
    transform: rotate(90deg);
}

#preguntas-frecuentes .accordion-panel .is-open .plus-icon:after {
    opacity: 0;
}

#preguntas-frecuentes .accordion-panel .hidden {
    display: none;
}

#preguntas-frecuentes .accordion-panel .open-btn,
#preguntas-frecuentes .accordion-panel .close-btn {
    cursor: pointer;
    position: absolute;
    right: 0;
    width: 90px;
}

#preguntas-frecuentes .accordion-panel .buttons-wrapper {
    position: relative;
}

#preguntas-frecuentes .accordion-panel .buttons-wrapper .plus-icon {
    position: absolute;
    right: 10px;
    top: 12px;
}


/***********************FIN CSS************************/


/***********************CSS OFICINAS PAISES**************/

#oficinas-paises .open-btn,
#oficinas-paises .close-btn {
    right: 12px !important;
}

#oficinas-paises .accordion-panel {
    margin: 10px 50px;
    max-width: 100%;
}

#oficinas-paises .accordion-panel .accordion {
    padding-top: 0px;
}

#oficinas-paises .accordion-panel .accordion dt {
    display: block;
    /*padding: 15px 25px;*/
    color: #555;
    cursor: pointer;
    position: relative;
    user-select: none;
    text-align: justify;
}

#oficinas-paises .accordion-panel .accordion dd {
    height: 0;
    overflow: hidden;
    transition: height .35s ease-out;
    margin-left: 0;
    margin-bottom: 5px;
    background: #f7f7f7;
}

#oficinas-paises .accordion-panel .accordion .content {
    padding: 25px;
    overflow: auto;
}

.accordion-panel .accordion .content p {
    text-align: justify;
}

#oficinas-paises .accordion-panel .plus-icon {
    display: inline-block;
    width: 15px;
    height: 15px;
    position: relative;
    transition: transform 0.35s ease-out;
    position: absolute;
    right: 35px;
    top: 58%;
    margin-top: -12px;
}

#paisesOficinas a {
    color: initial;
    font-size: 10pt;
    /*text-decoration: underline;*/
    line-height: 1.5;
    /* float: left;*/
}

.titularSm {
    padding-top: 20px;
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 25px;
}

#vistaLista .bandera-pais {
    position: absolute;
    right: 20px !important;
    width: 30px;
    max-width: 100%;
    filter: drop-shadow(1px 1px 1px #333232);
    border-radius: 13px;
    float: right;
    top: 13px;
}

#vistaCuadricula .bandera-pais {
    position: absolute;
    right: 13px;
    width: 30px;
    max-width: 100%;
    filter: drop-shadow(1px 1px 1px #333232);
    border-radius: 13px;
    float: right;
    top: -5px;
}

#oficinas-paises .accordion-panel .plus-icon:before,
#oficinas-paises .accordion-panel .plus-icon:after {
    content: '';
    background: #A0E000;
    position: absolute;
    text-shadow: 1px 1px 13px #000;
}

#carpeta {
    position: relative;
    width: 35px;
}

.avatar-perfil {
    position: relative;
    top: -2px;
}

#oficinas-paises .accordion-panel .plus-icon:before {
    width: 2px;
    height: 15px;
    margin-left: -0.5px;
    left: 50%;
}

#oficinas-paises .accordion-panel .plus-icon:after {
    width: 15px;
    height: 2px;
    margin-top: -0.5px;
    top: 50%;
    opacity: 1;
    transition: opacity 0.35s ease-out;
}

.sub-titular {
    color: #435265;
    font-weight: bold;
    font-size: 20pt;
    margin: 10px;
    margin-top: 0;
}

#oficinas-paises .accordion-panel .is-open .plus-icon {
    transform: rotate(90deg);
}

#oficinas-paises .accordion-panel .is-open .plus-icon:after {
    opacity: 0;
}

#oficinas-paises .accordion-panel .hidden {
    display: none;
}

#oficinas-paises .accordion-panel .open-btn,
#oficinas-paises .accordion-panel .close-btn {
    cursor: pointer;
    position: absolute;
    right: 0;
    width: 90px;
}

#oficinas-paises .accordion-panel .buttons-wrapper {
    position: relative;
}

#oficinas-paises .accordion-panel .buttons-wrapper .plus-icon {
    position: absolute;
    right: 10px;
    top: 12px;
}


/***********************FIN CSS************************/

#menudos .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #A0E000 !important;
}

.centrarTexto {
    position: relative;
    top: 50%;
    vertical-align: middle;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.textocirculo {
    float: left;
    vertical-align: middle;
    word-wrap: break-word;
    right: 13px;
    position: relative;
    width: 230px;
    font-size: 20px;
    min-height: 72px;
    height: 72px;
    font-family: Montserrat-Bold;
}

#id_menuheaderRedes {
    position: relative;
    right: 0;
    top: 45%;
    vertical-align: middle;
    transform: translateY(-50%);
    z-index: 10;
    width: 85px;
    margin-right: 10%;
}

#id_menuheaderP {
    position: relative;
    right: 15px;
    vertical-align: middle;
    top: 0px;
    flex-direction: inherit;
}

#conte-previa-carpeta {
    width: 320px;
    font-size: .9rem;
}

#conte-previa-carpeta-movil {
    width: 250px;
    font-size: .78rem;
}

.fila-previa-carpeta {
    border-bottom: 1px solid #dddddd;
}

#search li {
    display: inline-block;
    float: none;
}

#menu_principal .dropdown:hover > .dropdown-menu {
    display: block;
}

#menu_principal .dropdown > .dropdown-toggle:active {
    /*Without this, clicking will make it sticky*/
    pointer-events: none;
}

.login-movil {
    display: none !important;
}


/*#search li:nth-child(1) ul li:nth-child(2){
     display: none !important;
}*/

.logo_horizontal {
    position: relative;
    top: 50%;
    vertical-align: middle;
    transform: translateY(-50%);
    z-index: 10;
    width: 150px;
    float: left;
    margin-left: 10%;
}

.logo_horizontal img {
    max-width: 100%;
}

#id_menuheaderRedes > li > a {
    width: 85px;
    font-size: 1.3rem;
}

#textoCabecera {
    position: absolute;
    top: 50%;
    vertical-align: middle;
    transform: translateY(-50%);
    z-index: 1;
    color: #ffffff;
    padding-bottom: 0;
    font-family: Montserrat-Bold;
    font-size: 14px !important;
}

#id_menuheaderRedes > li > a:hover,
#id_menuheaderRedes > li > a:focus {
    background-color: transparent;
}

#search.nav > li > a {
    padding: 1.7rem 1.7rem !important;
    font-size: 1.4rem;
}

.dropdown-menu > li > a {
    font-size: 1.1rem;
    color: #080C38;
}

#franja-verde {
    background-image: url('../img/cintillo_gris_pc.png');
    width: 100%;
    height: 38px;
    text-align: center;
    /* background-size: 100%;*/
    background-repeat: no-repeat;
    display: table;
    background-position: top;
}

.menu-der {
    background: #080C38;
    float: right;
    transform: translateY(-50%);
    top: 50%;
    position: relative;
    vertical-align: middle;
    right: 80px;
}

#texto-franja-verde {
    color: #414042;
    text-align: center;
    font-size: 13px;
    font-family: Montserrat-Bold;
    margin-top: 0px;
    position: relative;
    top: 0px;
    vertical-align: middle;
    display: table-cell;
}

#headerMenu {
    font-size: 14px !important;
}

.img_scroll {
    right: 0;
    left: 162px !important;
    top: -42px !important;
    margin: auto;
    display: none;
    position: absolute;
    background-repeat: no-repeat;
    left: 110px;
}

.fixed {
    display: block;
}

.mundo-ofic img {
    width: 530px;
    float: right;
}

footer {
    margin: 0 !important;
}

#links li {
    padding: 0px;
}

#links li a {
    text-align: center;
    border-right: 1px solid #E3E3E3 !important;
    padding: 5px 0px;
}

.link_icon {
    float: left;
    min-width: 45px;
    width: auto;
    padding: 6px !important;
}

.fixed {
    position: fixed;
    top: 0
}

.mayusculas {
    text-transform: uppercase;
}
.mt-buscador {
    margin-top: 0;
}

/*#search li{padding-right: 0px !important; padding-left: 0px;}*/

.botonera-categorias .grisInactivo {
    background-color: #D9D9D9 !important;
}

.botonera-categorias .categorias-colores {
    display: table-cell;
    vertical-align: middle;
}

.botonera-categorias .categoria_cuadros {
    padding: 10px;
    z-index: 2;
}

.botonera-categorias .cursos {
    padding: 0px;
    color: #fff;
    width: 150px;
    height: 150px;
    display: table;
    margin: 0 auto;
    border-radius: 4px;
}

.titulo-boton-categoria {
    font-size: 17px;
}

.botonera-categorias .cursos img {
    width: 60px;
    margin: 10px auto 5px auto;
}

.borrarLeft {
    margin-left: unset !important;
}

.left1 {
    margin-left: -1px;
}


/* testimonials */

#testimoniales {
    margin: 35px 0 0px 0px;
    padding: 20px 40px 0;
}

.content-comillas {
    width: 120px;
    height: 120px;
    background: #A0E000;
    display: table;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
}

.content-comillas2 {
    display: table-cell;
    vertical-align: middle;
}

.content-comillas2 img {
    width: 70px;
    margin: 0 auto;
    display: block;
    max-width: 100%;
    height: auto;
}

.control-testimonio {
    font-size: 30px;
}

.cont-testimonio {
    height: 300px;
    width: 100%;
    position: relative;
    background-color: #ffffff;
    border-radius: 0 50px 0 50px;
    padding: 0 !important;
}

.thumbnail {
    background-color: #ffffff;
    padding: 0;
    box-shadow: 9px 9px 9px 0px #a5a5a554;
    margin: 10px;
}

#carousel-noticias .thumbnail {
    background-color: #ffffff;
    padding: 0;
    box-shadow: none;
    margin: 10px;
}

#testimoniales .thumbnail {
    border-radius: 0 50px 0 50px;
}

.front-testimonio,
.back-testimonio {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.front-testimonio {
    color: #FFFFFF;
    height: 400px;
    text-align: center;
    background-size: contain !important;
    background-position: center !important;
    padding: 95px 40px 5px;
    z-index: 2;
    transform: rotateY(0deg);
    transition: all 0.5s linear;
}

.back-testimonio {
    text-align: left;
    height: 400px;
    background-size: contain !important;
    background-position: center !important;
    padding: 75px 40px 5px 55px;
    transition: all 0.5s linear;
    transform: rotateY(180deg);
}

.front-testimonio-hover {
    transform: rotateY(180deg);
}

.back-testimonio-hover {
    transform: rotateY(360deg);
}

.imgTestimonio {
    width: 70px;
    margin: 0 auto;
    display: block;
    max-width: 100%;
    height: auto;
}

.contentImgTestimonio {
    height: 300px;
    background-size: cover !important;
    background-position: center !important;
    border-radius: 0 0 0 50px;
}

.contentImgTestimonio2 {
    display: table-cell;
    vertical-align: middle;
}

.nombre_testimonio {
    font-size: 1.5rem;
}

.descripcion-testimonios {
    font-size: .95rem;
    height: 125px;
    overflow-y: auto;
    overflow-x: hidden;
}

#carousel-noticias .carousel-item {
    min-height: 263px;
    max-height: 263px;
}

.back-testimonio .nombre_testimonio {
    padding: 7px 10px 0 0;
    margin-bottom: 10px;
}

.paisTestimonio {
    float: left;
    font-size: 18px;
    margin-top: 5px;
    width: 100%;
    padding: 0 50px;
}

.back-testimonio .paisTestimonio {
    margin-top: 10px;
    padding: 0 80px 0 0;
}

.descripcion_testimonio {
    font-family: Montserrat-Regular;
    font-size: 14px;
    text-align: left;
    height: 180px;
    padding-top: 30px;
    overflow: auto;
    padding-right: 10px;
    line-height: 1.6;
}

.btn[disabled] {
    background: #080C38 !important;
}

.descripcion_testimonio::-webkit-scrollbar-corner {
    background-color: #909090;
}

.descripcion_testimonio::-webkit-scrollbar-button {
    background-color: rgba(146, 148, 151, 0.46);
}

.descripcion_testimonio::-webkit-scrollbar {
    width: 9px;
}

.descripcion_testimonio::-webkit-scrollbar-track {
    background-color: #f4f4f4;
}

.descripcion_testimonio::-webkit-scrollbar-thumb {
    background-color: rgba(187, 189, 194, 0.2);
}

.descripcion_testimonio::-moz-scrollbar-corner {
    background-color: #909090;
}

.descripcion_testimonio::-moz-scrollbar-button {
    background-color: rgba(146, 148, 151, 0.46);
}

.descripcion_testimonio::-moz-scrollbar {
    width: 9px;
}

.descripcion_testimonio-list::-moz-scrollbar-track {
    background-color: #f4f4f4;
}

.descripcion_testimonio::-moz-scrollbar-thumb {
    background-color: rgba(187, 189, 194, 0.2);
}

.descripcion_testimonio::-ms-scrollbar-button {
    background-color: rgba(146, 148, 151, 0.46);
}

.descripcion_testimonio::-ms-scrollbar {
    width: 9px;
}

.descripcion_testimonio::-ms-scrollbar-track {
    background-color: #f4f4f4;
}

.descripcion_testimonio::-ms-scrollbar-thumb {
    background-color: rgba(187, 189, 194, 0.2);
}

.rect-testimonio {
    width: 40px;
    height: 160px;
    position: absolute;
}

.rect-testimonio.der {
    right: 0;
    margin-top: -50px;
}

.rect-testimonio.izq {
    left: 0;
    margin-top: -140px;
}

.titulo-oficinas-home h2,
.titulo-testimonio h2 {
    margin-top: 70px;
}


/**    BUSCADOR BANNER      **/

.buscador-banner {
    position: relative;
    top: -35px;
    text-align: center;
    width: 100%;
}

.content-buscador-banner {
    position: relative;
    left: 0;
    right: 0;
    bottom: 350px;
}

#buscar-cursos-prgramas {
    width: 100%;
    font-family: 'Montserrat-SemiBold';
    font-size: 1.5rem !important;
    margin: 5px 0;
}

.content-buscador-banner .campo-buscador {
    padding: 10px 10px 14px 10px;
}

.content-buscador-banner .campo-buscar {
    padding: 10px 10px 9px 10px;
}

.content-buscador-banner .campo-buscador .form-control button,
.content-buscador-banner .campo-buscador .form-control button:hover,
.content-buscador-banner .campo-buscador .form-control button:focus {
    color: #414042 !important;
    background: #FFFFFF !important;
    border: 1px solid #FFFFFF;
    outline: none !important;
}


/******     DATEPICKER  ************/

.datepicker td.active {
    background-image: none !important;
}

.datepicker-inline {
    padding: 0 0 5px 0 !important;
}

.cont-pais-ofic {
    width: 110px;
    height: 110px;
    border-radius: 84px;
    background: #F2F2F2;
    padding: 30px 20px 20px 20px;
    margin: 10px auto;
}

.titulo-boton-categoria {
    margin-top: 12px !important;
}

.logo-nuevo {
    position: absolute;
    width: 40px !important;
    right: 11px;
    top: -17px;
}

.cont-princ a {
    color: #414042 !important;
    text-decoration: underline;
}

.h5,
h5 {
    font-size: 1.5rem;
}

.container-banner-promocional {
    padding: 5rem 0;
}


/************************** FOOTER ***********************************/

#divPorqueLC .imgs .imgporque {
    margin-top: 10px;
    cursor: pointer;
}

.texto-porque {
    color: #425165;
    font-size: 16px;
    padding: 16px 32px;
}

.texto-porque h3 {
    font-weight: bold;
    color: #425165;
    font-size: 16px;
    padding: 16px 32px;
}

.cont_noticias {
    margin: 20px auto;
}

.noticia {
    background-color: #4d6eb5;
    color: #FFF;
    /*padding: 25px;*/
}

.noticia .titulo_noticia {
    font-weight: bold;
    font-size: 26px;
    text-align: justify;
}

.noticia .subtitulo_noticia {
    font-size: 18px;
    text-align: left;
    font-family: Montserrat-Bold;
}

.btn-leermas {
    background-color: #A0E000;
    width: 150px;
    margin-top: 23px;
    font-size: 16px;
}

.btn-leermas:hover {
    background-color: #70C65C;
    border-color: #70C65C;
    color: #FFFFFF !important;
}
.btn-verde {
    background-color: #9ac734 !important;
    border-color: #9ac734 !important;
    width: 150px;
    margin-top: 23px;
    font-size: 16px;
    color: #FFFFFF;
}

.btn-verde:hover {
    background-color: #080C38 !important;
    border-color: #080C38 !important;
    color: #FFFFFF !important;
}
.pies li {
    padding: 0;
}

.footer-titulos h6 {
    color: #A0E000;
    font-family: Rubik-SemiBold;
    font-size: 1.1rem;
}

#footer .ulNavegacionPies {
    list-style: none;
    padding-left: 15px;
}

#footer .pt-7 {
    padding-top: 5rem !important;
    padding-bottom: 3rem !important;
}

#footer .ulNavegacionPies > li > a {
    text-decoration: none;
    line-height: 2.5;
    font-size: .95rem;
}

#footer .ulNavegacionPies > li > a:hover,
#footer .ulNavegacionPies > li > a:focus {
    background-color: transparent !important;
    color: #A0E000 !important;
}

.sociales a {
    text-decoration: none;
    color: #A0E000;
}

.container-fluid > .navbar-header {
    margin-right: 0 !important;
    margin-left: 0 !important;
    height: 48px;
}

.carousel-control.right,
.carousel-control.left {
    background: none !important;
    color: #FFFFFF !important;
}


/* PORQUE LC */

.image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    right: -45%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%)
}

.middle p {
    font-size: 10px;
}

.imgporque:hover .image {
    opacity: 0.3;
}

.imgporque:hover .middle {
    opacity: 1;
}


/*.text {
    background-color: #A0E000;
    color: white;
    font-size: 16px;
    padding: 16px 32px;
}*/


/*paginador*/

.pagination > li > a,
.pagination > li > span {
    color: #080C38 !important;
}

.fuenteLight {
    font-family: Montserrat-Light;
}

.pagination > li > a,
.pagination > li > span {
    border: 1px solid #ffffff;
}

.pagination > .active > a {
    color: #FFFFFF !important;
    background-color: #A0E000;
    border-color: #A0E000;
}

.pagination > .active > a:hover {
    background-color: #080C38;
    border-color: #080C38;
    color: #fff !important;
}

.pagination > li:hover {
    color: #080C38 !important;
}

.pagination > .disabled > a {
    color: #080C38 !important;
    border-color: #ffffff;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
    background-color: #080C38 !important;
    border-color: #080C38 !important;
    color: #fff !important;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.pagination > li:nth-last-child(2) > a,
.pagination > li:nth-last-child(2) > span {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}


/*categorías*/

.categorias-item-content {
    padding: 0 5px;
    border-bottom: 5px solid transparent;
    border: 1px solid #CECDD1;
    border-radius: 0 0 10px 10px;
    background: #ffffff;
}

.categorias-item-img {
    position: relative;
    /*margin: -15px -15px 15px -15px;*/
    border-bottom: 1px solid #CECDD1;
}

.categorias-item-price p {
    color: #fff;
    font-weight: bold;
    text-align: center;
    padding: 5px;
}


/*manu mejoras*/


/*sociales*/

.social-gm {
    color: #dd5044;
}

.social-tw {
    color: #55ACEE;
}

.social-ws {
    color: #00C853;
}

.social-lk {
    color: #0082CA;
}


/*preguntas frecuentes*/

.preguntas li:before {
    content: "O";
    /* Insert content that looks like bullets */
    padding-right: 8px;
    color: #A0E000;
    /* Or a color you prefer */
}

.margen-texto {
    padding: 15px;
    background: #f0f0f0;
}


/*boostrap select*/

.bootstrap-select .text {
    background-color: #fff;
    color: #000;
    font-size: 16px;
    padding: 0px;
}

.bootstrap-select .text:hover {
    background-color: #fcfcfc;
}

.bootstrap-select .dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
    color: #000;
    text-decoration: none;
    background-color: #fcfcfc;
    outline: 0;
}


/* carousel para todo*/

.carousel-control {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 15%;
    font-size: 20px;
    color: #fff;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
    background-color: rgba(0, 0, 0, 0);
    filter: alpha(opacity=50);
    opacity: .5;
}

.carousel-caption h1 {
    font-weight: bold;
    font-size: 3.5rem;
}

.carousel-caption {
    text-align: left;
    position: absolute;
    width: 100%;
    vertical-align: middle;
    display: table-cell;
    right: inherit;
    bottom: inherit;
    left: inherit;
    top: 23%;
}

.fuenteNegrita {
    font-weight: bold !important;
}

.fuenteAzulOscuro {
    color: #425165 !important;
}

.fuenteVerde {
    color: #A0E000 !important;
    font-family: Montserrat-Bold;
}

.sin_rotacion {
    transform: rotate(0deg) !important;
    -webkit-transform: rotate(0deg) !important;
    -moz-transform: rotate(0deg) !important;
}

.descripcion {
    padding: 30px;
    word-wrap: break-word;
}

.controles {
    cursor: pointer;
    font-size: 16pt;
}

.open > .dropdown-toggle.btn-default:hover {
    color: #fff;
    background-color: #70C65C;
    border-color: #70C65C;
}

.dropdown-toggle.btn-default:hover {
    color: #fff !important;
    background-color: #70C65C;
    border-color: #70C65C;
}

.btn-default:focus {
    color: #FFF !important;
    background-color: #70C65C !important;
    border-color: #70C65C !important;
    outline: transparent !important;
}


/*menu de usuario*/

#menu-usuario .dropdown-menu {
    min-width: 135px;
}

#galeria .col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
}

#menu-usuario .opciones {
    margin-top: 15px;
}


/*********************STEPS TO STEPS*****************************/

.stepper .nav-tabs {
    position: relative;
}

.stepper .nav-tabs > li {
    position: relative;
}

.stepper .nav-tabs > li:after {
    content: '';
    position: absolute;
    background: #f1f1f1;
    display: block;
    width: 100%;
    height: 5px;
    top: 40px;
    left: 50%;
    z-index: 1;
}

.stepper .nav-tabs > li:last-child:after {
    content: '';
    position: absolute;
    background: #f1f1f1;
    display: block;
    width: 100%;
    height: 5px;
    top: 40px;
    left: 0;
    z-index: 1;
}

.stepper .nav-tabs > li.completed::after {
    background: #002460;
}

.stepper .nav-tabs > li:last-child::after {
    background: transparent;
}

.stepper .nav-tabs > li.active:last-child .round-tab {
    background: #002460;
}

.stepper .nav-tabs > li.active:last-child .round-tab::after {
    content: '✔';
    color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 0;
    display: block;
    padding: 7px;
}

.stepper .nav-tabs [data-toggle='tab'] {
    width: 40px;
    height: 40px;
    margin: 20px auto;
    border-radius: 100%;
    border: none;
    padding: 0;
    color: #f1f1f1;
}

.stepper .nav-tabs [data-toggle='tab']:hover {
    background: transparent;
    border: none;
}

.stepper .nav-tabs > .active > [data-toggle='tab'],
.stepper .nav-tabs > .active > [data-toggle='tab']:hover,
.stepper .nav-tabs > .active > [data-toggle='tab']:focus {
    color: #002460;
    cursor: default;
    border: none;
}

.stepper .tab-pane {
    position: relative;
    padding-top: 20px;
}

.stepper .round-tab {
    width: 40px;
    height: 40px;
    line-height: 22px;
    display: inline-block;
    border-radius: 25px;
    background: #fff;
    border: 2px solid #002460;
    color: #002460;
    z-index: 2;
    position: absolute;
    left: 0;
    text-align: center;
    font-size: 20px;
    padding: 7px;
}

.stepper .completed .round-tab {
    background: #002460;
}

.stepper .completed .round-tab::after {
    content: '✔';
    color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 0;
    display: block;
    padding: 7px;
}

.stepper .active .round-tab {
    background: #fff;
    border: 2px solid #002460;
}

.stepper .active .round-tab:hover {
    background: #fff;
    border: 2px solid #002460;
}

.stepper .active .round-tab::after {
    display: none;
}

.stepper .disabled .round-tab {
    background: #fff;
    color: #f1f1f1;
    border-color: #f1f1f1;
}

.stepper .disabled .round-tab::after {
    display: none;
}

.panel-default {
    border-color: transparent;
    margin-bottom: 0;
}

.tab-content {
    padding: 0 5%;
}

.contenidor-pasos {
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: -4px 2px 20px 9px #2b346614;
}

.titulos-informacion-personal {
    margin: 15px 0 20px 0px;
    font-weight: bold;
    padding-left: 0 !important;
    font-family: Montserrat-Bold;
    font-size: 1.25rem;
}

.text-justify {
    text-align: justify;
}

.flatpickr-calendar.open {
    z-index: 999;
}


/*  SCROLL STYLE */

.scroll-style::-webkit-scrollbar-corner {
    background-color: #909090;
}

.scroll-style::-webkit-scrollbar-button {
    background-color: rgba(146, 148, 151, 0.46);
}

.scroll-style::-webkit-scrollbar {
    width: 9px;
}

.scroll-style::-webkit-scrollbar-track {
    background-color: #f4f4f4;
}

.scroll-style::-webkit-scrollbar-thumb {
    background-color: rgba(187, 189, 194, 0.2);
}

.scroll-style::-moz-scrollbar-corner {
    background-color: #909090;
}

.scroll-style::-moz-scrollbar-button {
    background-color: rgba(146, 148, 151, 0.46);
}

.scroll-style::-moz-scrollbar {
    width: 9px;
}

.scroll-style::-moz-scrollbar-track {
    background-color: #f4f4f4;
}

.scroll-style::-moz-scrollbar-thumb {
    background-color: rgba(187, 189, 194, 0.2);
}

.scroll-style::-ms-scrollbar-button {
    background-color: rgba(146, 148, 151, 0.46);
}

.scroll-style::-ms-scrollbar {
    width: 9px;
}

.scroll-style::-ms-scrollbar-track {
    background-color: #f4f4f4;
}

.scroll-style::-ms-scrollbar-thumb {
    background-color: rgba(187, 189, 194, 0.2);
}

@media (min-width: 576px) {
    .container-banner-promocional {
        max-width: 540px;
    }

    .contenedor-select {
        max-width: 500px;
    }

}

@media (min-width: 768px) {
    /*#search .dropdown:hover .dropdown-menu,
    #search .dropdown:active .dropdown-menu,
    #search .dropdown .dropdown-menu {
        display: block;
    }*/

    .nav > li > a:hover {
        border-bottom: 3px solid #080C38;
    }

    .container-banner-promocional {
        max-width: 720px;
    }

    .contenedor-select {
        max-width: 700px;
    }
}

@media (min-width: 992px) {
    .container-banner-promocional {
        max-width: 960px;
    }

    .contenedor-select {
        max-width: 860px;
    }
}

@media (min-width: 1201px) {
    .container-banner-promocional {
        max-width: 1140px;
    }

    .contenedor-select {
        max-width: 1000px;
    }
}

@media (min-width: 1400px) {
    .container-banner-promocional {
        max-width: 1320px;
    }

    .contenedor-select {
        max-width: 1200px;
    }
}

@media only screen and (min-width: 1601px) {
    .top-pequeno {
        padding: 5em 0.9375em 0 11em;
    }

    #search .nav > li > a {
        padding: 1.7rem 1.4rem !important;
    }
}

@media only screen and (min-width: 1921px) {
    #search.nav > li > a {
        padding: 1.7rem 1.7rem !important;
        font-size: 1.65rem;
    }

    .dropdown-menu > li > a {
        font-size: 1.1rem;
    }
}


/*********   min   2560     **********/

@media only screen and (min-width: 2560px) {
    #franja-verde {
        background-size: 100% !important;
    }

    #VideoB {
        width: 80px !important;
    }

    .subtit {
        font-size: 14pt !important;
    }

    .footer-titulos p {
        font-size: 1.5rem !important;
    }

    #footer .navbar-nav > li > a {
        padding-bottom: 12pt !important;
    }

    .botonera-categorias .cursos {
        width: 100%;
        height: 180px;
    }

    .botonera-categorias .cursos img {
        width: 104px;
    }

    .botonera-categorias {
        width: 1200px;
    }

    #search.nav > li > a {
        padding: 1.7rem 1.4rem !important;
        font-size: 1.5rem;
    }

    .logo-nuevo {
        width: 50px !important;
        right: 0;
        top: -30px;
    }
}


/*********      1920     **********/

@media only screen and (max-width: 1920px) {
    .tit {
        padding: 25px 25px 15px 25px !important;
    }

    .subtit {
        font-size: .65em;
    }

    .img_scroll {
        top: -52px !important;
    }

    #divNoticias .noticia {
        background-size: 70% !important;
    }

    #search .nav > li > a {
        padding: 10px 10px !important;
    }

    .botonera-categorias .cursos {
        width: 140px;
        height: 140px;
    }

    .botonera-categorias .cursos img {
        width: 60px;
    }

    .titulo-boton-categoria {
        font-size: 15px;
    }

    .banners .carousel-inner,
    .banners,
    .img_banners,
    .banners .carousel-inner .item,
    .video-banner {
        min-height: 650px !important;
        max-height: 650px !important;
    }

    #previaCarpeta {
        right: -0px !important;
        top: 0px !important;
        position: relative;
    }

    .subtitulo-pag {
        font-size: 13px !important;
    }

    .titulo-h1-banner {
        font-size: 3.2rem !important;
    }

    .subtitulo-h1-banner {
        font-size: 1.4rem;
    }

    .titulo-buscador-banner {
        font-size: 2.8rem;
    }
}

@media only screen and (max-width: 1800px) {
    .carousel-caption {
    }
}


/*********      1600     **********/

@media only screen and (max-width: 1600px) {
    #contenedor-menu.container {
        width: 1122px;
    }

    .titulo-h1-banner {
        font-size: 3rem !important;
    }

    .texto-pathways {
        font-size: 1.8rem;
    }

    .tit {
        font-size: 1.7em !important;
    }

    .banners .carousel-inner .item {
        background-position: center !important;
    }

    .top-pequeno {
        padding: 5em 0.9375em 0 11em;
    }

    #notificacion_contador {
        padding: 0 0 0 6px;
        font-size: .8rem;
        width: 20px;
        height: 20px;
        top: 0px;
    }

    #divNoticias .noticia {
        background-size: 81% !important;
    }

    .botonera-categorias .cursos img {
        width: 60px;
    }

    .botonera-categorias .cursos {
        width: 120px;
        height: 120px;
    }

    .titulo-boton-categoria {
        font-size: 13px;
    }

    #previaCarpeta {
        right: -75px !important;
        top: 29px !important;
    }

    .future {
        font-size: 1.5rem;
    }

    #search .nav > li > a {
        font-size: 1.6rem;
        padding: 1.2rem 1.2rem !important;
    }

    #buscar-cursos-prgramas {
        font-size: 1.5rem !important;
    }

    .subtitulo-pag {
        font-size: 12px !important;
    }

    .texto-para-seo {
        margin: 3% 0 4% 0;
    }

    #bannersEscritorio,
    #bannersEscritorio .carousel-inner,
    #bannersEscritorio .carousel-inner .carousel-item,
    #bannersEscritorio .embed-container,
    #bannersEscritorio .contenidoBannes {
        min-height: 560px !important;
        max-height: 560px !important;
    }

    .header-verde .navbar-default .navbar-nav > li > a,
    .header-verde .nav-link {
        color: #080C38;
        font-size: .95rem;
    }

    #carpeta {
        width: 32px;
    }

    .container-principal-porque-elegirnos {
        background-position: center;
    }

    .titulo-elegisrnos1 {
        font-size: 1.3rem;
    }

    .titulo-elegisrnos2 {
        font-size: 1.7rem;
    }

    .titulo-elegisrnos3 {
        font-size: 2.5rem;
    }

    .descripcion-elegisrnos {
        font-size: 1.2rem;
    }

    .logo_lc {
        width: 135px;
    }

    .carousel-caption h1 {
        font-size: 2.8rem;
    }

    .carousel-caption {
        top: 20%;
    }

    .subtitulo-h1-banner {

    }
}


/*********      1440     **********/

@media only screen and (max-width: 1550px) {
    /*.content-banner-promocion {
        padding: 25px 7%;
    }*/
    .titulo-h1-banner {
        font-size: 2.7rem !important;
    }
}

@media (max-width: 1400px) {
    .card-noticias {
        width: 22rem !important;
    }

    .card-destinos {
        width: 16rem !important;
    }

    .incono-mas {
        left: 0;
    }

    .avatar-perfil {
        width: 29px;
    }
}

@media only screen and (max-width: 1440px) {
    #contenedor-menu.container {
        width: 1030px;
    }

    #notificacion_contador {
        padding: 0px 10px 8px 5px;
        margin-left: 21px;
        font-size: .8rem;
        width: 18px;
        height: 18px;
        top: 2px;
        right: 9px;
    }

    .header-verde {
        min-height: 85px;
    }

    #previaCarpeta {
        right: -75px !important;
        top: 29px !important;
    }

    .botonera-categorias .cursos img {
        width: 55px;
    }

    .titulos_principales {
        font-size: 2.3em;
    }

    .tit {
        font-size: 1.7em !important;
        width: 350px !important;
        padding: 15px 15px 5px 15px !important;
    }

    #carpeta {
        width: 32px;
    }

    #menu-usuario img {
        width: 22px !important;
        height: 22px !important;
        margin-top: -3px !important;
    }

    #id_menuheaderRedes img {
        width: 22px !important;
    }

    #bs-content-row-navbar-collapse-5 {
        margin-right: 10px;
    }

    .titulo-seo-h2 {
        font-size: 18px;
        line-height: 1.5;
        text-align: justify;
        font-weight: bold;
    }

    .banners .carousel-inner,
    .banners,
    .img_banners,
    .banners .carousel-inner .item,
    .video-banner {
        min-height: 470px !important;
        max-height: 470px !important;
        height: 470px !important;
    }

    .banners .carousel-inner .item {
        background-position: center !important;
    }

    .img_scroll {
        top: -78px !important;
        left: 232px;
    }

    .iconos-categorias {
        margin-right: 6px;
    }

    #id_menuheaderP2 {
        right: 13px !important;
    }

    .RedesSociales1 a {
        font-size: 11px !important;
    }

    .menu-der {
        right: 20px;
    }

    .titularSm {
        font-size: 20px;
    }

    .noticia .subtitulo_noticia {
        font-size: 18px !important;
    }

    #VideoB {
        width: 35px;
    }

    .back-testimonio {
        padding: 70px 30px 5px 40px;
    }

    .back-testimonio .paisTestimonio {
        padding: 0 50px 0 0;
    }

    .front-testimonio {
        padding: 95px 30px 5px;
    }

    #testimoniales {
        margin: 0;
        padding: 20px 100px 50px;
    }

    #search .nav > li > a {
        padding: 1.2rem 1rem !important;
    }

    .mundo-ofic img {
        width: 417px;
    }

    .logo_horizontal {
        width: 130px;
    }

    .texto-pathways {
        width: 100%;
    }

    .titulos-h1 {
        font-size: 2.3em;
    }

    .subtituloP {
        font-size: 0.9rem;
    }

    .header-verde .navbar-default .navbar-nav > li > a,
    .header-verde .nav-link {
        font-size: 0.95rem;
    }

    #id_menuheaderP {
        justify-content: end !important;
    }

    .logo_lc {
        left: 0;
        width: 135px;
    }
}


/*********      1366     **********/

@media only screen and (max-width: 1366px) {
    .mundo-ofic img {
        width: 400px;
    }

    #notificacion_contador {
        top: 5px;
    }

    .imgtrian {
        width: 110px;
    }

    .future {
        font-size: 1.5rem;
    }

    .li-planificador {
        float: left;
        margin: 0px 17px 0 0;
    }

    .titulos_principales {
        font-size: 2.055em;
    }

    .titulos-h1 {
        font-size: 2.055em;
    }

    .texto-pathways {
        font-size: 1.65rem;
        width: 100%;
    }

    .content-comillas img {
        width: 65px !important;
    }

    .content-comillas {
        width: 110px;
        height: 110px;
    }

    .top-pequeno {
        padding: 5em 0.9375em 0 10em;
    }

    .banners .carousel-inner,
    .banners,
    .img_banners,
    .banners .carousel-inner .item,
    .video-banner {
        min-height: 440px !important;
        max-height: 440px !important;
        height: 450px !important;
    }

    .tit {
        font-size: 1.66em !important;
        padding: 20px 20px 10px 20px !important;
        left: 15%;
        height: 360px;
    }

    .content-buscador-banner {
        bottom: 275px;
    }

    .subtit {
        font-size: .7em !important;
    }

    #id_menuheaderP2 {
        right: 13px !important;
    }

    .RedesSociales1 a {
        font-size: 11px !important;
    }

    .menu-der {
        right: 20px;
    }

    .header-verde .navbar-default .navbar-nav > li > a,
    .header-verde .nav-link {
        font-size: .93rem;
    }

    .titularSm {
        font-size: 20px;
    }

    .noticia .subtitulo_noticia {
        font-size: 18px !important;
    }

    #previaCarpeta {
        right: -29px !important;
        top: 29px !important;
    }

    .titulo-boton-categoria {
        font-size: 13px;
    }

    .botonera-categorias .cursos img {
        width: 55px;
        margin: 5px auto;
    }

    .botonera-categorias .cursos {
        height: 120px;
        width: 120px;
    }

    .logo-nuevo {
        right: 22px;
    }

    #search .nav > li > a {
        font-size: 1.4rem;
        padding: 1.3rem 1.3rem !important;
    }

    #buscar-cursos-prgramas {
        font-size: 1.4rem !important;
    }

    .btn-default {
        font-size: 1rem !important;
    }

    subtituloP {
        font-size: 1.3rem;
    }

    #id_menuheaderP {
        justify-content: end !important;
    }

    #id_menuheaderP .px-3 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }

    .logo_lc {
        right: 10%;
        width: 120px;
    }

    .header-verde {
        min-height: 80px;
    }

    .titulo-porque-lc {
        font-size: 1.65rem;
    }

    .container-testimonios .texto-titulo-testimonio3 {
        font-size: 2.35rem;
    }

    .container-testimonios .texto-titulo-testimonio2 {
        font-size: 1.3rem;
    }

    .container-testimonios .texto-titulo-testimonio1 {
        font-size: 1.2rem;
    }
}


/*********      1280     **********/

@media only screen and (max-width: 1280px) {
    #id_menuheaderRedes > li > a {
        font-size: 1.1rem;
    }

    .titulo-seo-h2 {
        font-size: 17px;
    }

    .rayitaVerde {
        bottom: 30px;
    }

    .btn-default {
        font-size: 1rem !important;
    }

    .content-buscador-banner .campo-buscador {
        padding: 10px 10px 8px !important;
    }

    .content-buscador-banner .campo-buscar {
        padding: 10px 10px 9px 10px !important;
    }

    /* .btn-buscador {
        height:35px !important;
    }*/
    .tit {
        font-size: 1.5em !important;
        padding: 15px !important;
        top: 10px;
        height: 360px;
        left: 15%;
    }

    .subtit {
        font-size: .7em !important;
    }

    #VideoB {
        width: 33px !important;
    }

    #search .nav > li > a {
        padding: 10px 5px !important;
        font-size: 1.4rem;
    }

    .dropdown-menu > li > a {
        font-size: 1rem;
    }

    #menu-usuario .img-responsive {
        margin-top: -2px !important;
    }

    .back-testimonio .descripcion_testimonio {
        font-size: 13px !important;
        height: 185px !important;
    }

    .cont-testimonio,
    .front-testimonio,
    .back-testimonio {
        height: 370px;
    }

    .back-testimonio {
        padding: 70px 20px 5px 30px;
    }

    .front-testimonio {
        padding: 95px 25px 5px;
    }

    .texto-pathways {
        font-size: 1.6rem;
    }

    .tituloS {
        height: 225px;
    }

    .pagination > li > a,
    .pagination > li > span {
        font-size: 12px;
    }

    .container-testimonios .texto-titulo-testimonio3 {
        font-size: 2.35rem;
    }
}


/*********      1200     **********/

@media only screen and (max-width: 1199px) {
    .container-porque-lc {
        margin-top: 4rem !important;
        margin-bottom: 2rem !important;
    }
    .descripcion-asesores{
        line-height: 1.6;
    }
    .row-banner-home{
        height: 90px;
    }
    .titulo-seo-h2 {
        font-size: 17px;
    }

    #search .nav > li > a {
        padding: 9px 3px !important;
        font-size: 1.3rem;
    }

    .rayitaVerde {
        bottom: 30px;
    }

    .botonera-categorias .categoria_cuadros {
        padding: 6px;
    }

    #testimoniales {
        padding: 20px 50px 40px;
    }

    #search.nav > li > a {
        padding: 1.7rem 1.3rem !important;
        font-size: 1.18rem;
    }

    .logo-nuevo {
        right: 5px;
    }

    .titulos-h1 {
        font-size: 2em;
    }

    .texto-pathways {
        font-size: 1.5rem;
    }

    .titulos_principales {
        font-size: 2em;
    }

    .tituloS {
        height: 225px;
    }

    .logo_lc {
        right: 0;
        left: 30px;
        width: 125px;
    }

    .header-verde .navbar-default .navbar-nav > li > a,
    .header-verde .nav-link {
        font-size: .93rem;
    }

    .header-verde {
        min-height: 80px;
    }

    .hamburger-movil {
        top: 5px;
        right: 10px;
    }

    #menu_movil .sidebar,
    .overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 2;
    }

    #menu_movil .sidebar {
        position: relative;
        top: auto;
        bottom: auto;
        left: auto;
        flex-direction: row;
        max-width: 230px;
        min-width: 230px;
        transform: none;
        transition: none;
        overflow-y: visible;
        transition: all ease 0.25s;
        overflow-y: auto;
    }

    #menu_movil .cerrar_menu {
        right: 7px;
        top: 5px;
        width: 25px;
        height: 25px;
        cursor: pointer;
        z-index: 1035;
        transition: all ease 0.25s;
        color: #f60202;
    }

    .header-verde .dropdown-menu > li > a {
        font-size: .95rem;
    }

    #menu_movil .dropdown-item.active,
    .dropdown-item:active {
        color: #080C38;
    }

    #menu_movil .navbar-nav {
        display: flex;
        flex-direction: column !important;
        padding: 5px 20px;
        list-style: none;
    }

    #menu_movil .dropdown-menu {
        border: none !important;
    }

    .hamburger-movil .navbar-toggler {
        padding: 0.22rem 0.38rem !important;
        margin-right: 0 !important;
    }

    .container-primera-columna {
        text-align: left !important;
    }

    .lado-izquierdo-porque-lc {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }

    #paises .img-destinos {
        height: 200px;
    }

    .container-testimonios .texto-titulo-testimonio3 {
        font-size: 2.15rem;
    }

    .container-testimonios .texto-titulo-testimonio2 {
        font-size: 1.2rem;
    }

    .container-testimonios .texto-titulo-testimonio1 {
        font-size: 1.3rem;
    }

    .img-testominio {
        height: 70px;
        width: 70px;
    }

    .nombre_testimonio {
        font-size: 1.3rem;
    }

    .review-user,
    .review-google,
    .review-faceboock {
        font-size: 1.5em;
    }

    .card-noticias {
        width: 18rem !important;
    }

    .card-text {
        font-size: .85rem;
    }

    .texto-review-google {
        font-size: .8rem;
        bottom: 0;
    }

    .texto-review-faceboock {
        font-size: .8rem;
        bottom: 0;
    }

    .card-noticias ul li span {
        font-size: .85rem;
    }

    .card-noticias .card-title {
        font-size: 1rem;
    }

    .container-principal-porque-elegirnos {
        background-position: top;
        height: 650px;
    }

    .titulo-elegisrnos1 {
        font-size: 1.2rem;
    }

    .titulo-elegisrnos2 {
        font-size: 1.7rem;
    }

    .titulo-elegisrnos3 {
        font-size: 2.5rem;
    }

    .descripcion-elegisrnos {
        font-size: 1.2rem;
    }

    .container-testimonios .lado-izquierdo-testimonios {
        height: 640px;
    }

    .incono-mas {
        font-size: 1.2rem;
        top: 5px;
    }

    .col-social #ulRedesFooter li {
        height: 35px;
        width: 30px;
    }

    #ulRedesFooter i,
    #ulRedesFooter svg {
        font-size: 1.5em;
    }

    .container-paises {
        background: url(/static/img/home/fondo_mapa1200.png);
    }

    #notificacion_contador {
        padding: 1px 10px 8px 6px;
        margin-left: 0;
        font-size: .72rem;
        width: 20px;
        height: 20px;
        top: 0px;
        right: 5px;
    }

    #carpeta {
        width: 32px;
    }

    #id_menuheader_movil {
        position: relative;
        right: 15px;
        vertical-align: middle;
        top: 5px;
        flex-direction: inherit;
    }

    .titulo-h1-banner {
        font-size: 2.8rem !important;
    }

    .subtitulo-h1-banner {

    }

    .bandera-pais {
        width: 20px;
    }
}


/*********      1110     **********/

@media (max-width: 1110px) {
    .titulos-h1 {
        font-size: 1.9em;
    }

    .texto-pathways {
        font-size: 1.5rem;
        width: 100%;
    }

    .titulos_principales {
        font-size: 1.9em;
    }

    .titulo-seo-h2 {
        font-size: 17px;
    }

    .botonera-categorias h3 {
        margin-top: 10px;
    }

    .future {
        font-size: 1.3rem;
    }

    .dropdown-menu > li > a {
        font-size: 1.2rem;
    }

    .rayitaVerde {
        bottom: 30px;
    }

    .future {
        top: -46px;
    }

    .content-comillas {
        width: 90px;
        height: 90px;
    }

    .content-comillas img {
        width: 55px !important;
    }

    .cont-testimonio,
    .front-testimonio,
    .back-testimonio {
        height: 320px;
    }

    .front-testimonio {
        padding: 80px 25px 5px;
    }

    .back-testimonio {
        padding: 55px 20px 5px 25px;
    }

    .paisTestimonio {
        font-size: 15px;
        padding: 0 30px;
    }

    .back-testimonio .descripcion_testimonio {
        font-size: 12px !important;
        height: 165px !important;
    }

    .back-testimonio .paisTestimonio {
        padding: 0 45px 0 0;
    }

    #testimoniales .indicadores {
        top: -172px !important;
    }

    .top-pequeno {
        padding: 5em 0.9375em 0 9em;
    }

    #search.nav > li > a {
        padding: 1.7rem 1.6rem !important;
        font-size: 1.1rem;
    }

    .tituloS {
        height: 200px;
    }

    .texto-para-seo {
        margin: 3% 0 5% 0;
    }

    .tit {
        left: 10%;
    }

    .header-verde .navbar-default .navbar-nav > li > a,
    .header-verde .nav-link {
        font-size: .9rem;
    }
}

@media (max-width: 1099px) {
    .top-pequeno {
        padding: 5em 0.9375em 0 7em;
    }
}


/*********      1024     **********/

@media (max-width: 1024px) {
    .titulo-seo-h2 {
        font-size: 15px;
    }

    .titulo-boton-categoria {
        font-size: 13px;
    }

    .logo-nuevo {
        right: -2px;
        width: 40px !important;
        top: -24px;
    }

    .rayitaVerde {
        bottom: 30px !important;
    }

    .top-pequeno {
        padding: 5em 0.9375em 0 8em;
    }

    #headerMenu {
        font-size: 12px !important;
    }

    #headerMenu .glyphicon-globe {
        font-size: 14px !important;
    }

    #lupa-buscar span {
        font-size: 14px !important;
    }

    #previaCarpeta {
        right: -41px !important;
    }

    .carpeta-listado-curso #notificacion_contador {
        margin-left: 17px !important;
        width: 18px !important;
        height: 18px !important;
        font-size: 10px !important;
        padding: 1px 7px 3px 4px !important;
    }

    #ulRedesFooter li {
        height: 30px !important;
        width: 30px !important;
    }

    #ulRedesFooter svg,
    #ulRedesFooter i {
        font-size: 1.5rem !important;
    }

    #id_menuheaderP2 i {
        font-size: 0.8em !important;
    }

    #id_menuheaderRedes {
        top: 48%;
        margin-right: 3%;
    }

    .banners .carousel-inner,
    .banners,
    .banners .carousel-inner .item,
    .video-banner {
        min-height: 400px !important;
        max-height: 400px !important;
        height: 400px !important;
    }

    .img_banners {
        min-height: inherit !important;
        max-height: 650px !important;
    }

    .content-buscador-banner .campo-buscador button {
        height: 34px;
    }

    .btn-default {
        font-size: 1rem !important;
    }

    #franja-verde {
        background-size: 100% 100%;
        /* height: 40px !important; */
        background-position: center;
    }

    #divNoticias .noticia {
        min-height: 200px !important;
        height: 200px !important;
    }

    .titularSm {
        font-size: 15px !important;
    }

    .subtitulo_noticia p {
        font-size: 13px !important;
    }

    table.display td {
        padding: 5px 0 !important;
    }

    #texto-franja-verde {
        font-size: 12px !important;
    }

    .caption {
        text-align: center;
    }

    .img_scroll {
        left: 50px !important;
    }

    .imgtrian {
        top: -40px !important;
        width: 100px;
    }

    #search .nav > li > a {
        padding: 5px 3px !important;
        font-size: 1.2rem;
    }

    .margen {
        margin-left: -55px !important;
    }

    .img_scroll {
        top: -23px !important;
    }

    .botonera-categorias .cursos img {
        width: 45px;
    }

    .botonera-categorias .cursos {
        height: 116px;
        width: 100%;
    }

    #VideoB {
        width: 30px !important;
    }

    .back-testimonio {
        padding: 55px 15px 5px 20px;
    }

    .paisTestimonio {
        font-size: 13px;
    }

    .cont-testimonio,
    .front-testimonio,
    .back-testimonio {
        height: 260px;
    }

    .contentImgTestimonio {
        height: 260px;
    }

    .titulos_principales {
        font-size: 1.7em;
    }

    .titulos-h1 {
        font-size: 1.7em;
    }

    .texto-pathways {
        font-size: 1.5rem;
        width: 100%;
    }

    .logo_horizontal {
        margin-left: 5%;
        width: 125px;
    }

    .favic {
        width: 20px !important;
        height: 20px !important;
    }

    #id_menuheaderRedes img {
        width: 22px !important;
    }

    .tit {
        padding: 17px !important;
    }

    .content-buscador-banner {
        bottom: 260px;
    }

    .descripcion_testimonio {
        font-size: 12px;
    }

    .subtitulo-h1-banner {

    }

    .a-enlace-banner {
        margin: 1.5rem 0 2.5rem;
        padding-left: 40px;
    }

    #bannersEscritorio .boton-banner-promo {
        margin-left: 40px;
    }

    .content-banner-promocion {
        padding: 5rem 0;
        margin-left: 5%;
        margin-right: 5%;
        margin-bottom: 6%;
    }

    .titulo-bannerP {
        font-size: 1.15rem;
    }

    .rowBannerP .subtituloP {
        line-height: 1.2;
        font-size: 1.3rem;
    }

    .col-text-bannerP {
        padding: 1rem 2.5rem !important;
    }

    .col-text-bannerP > .row:not(.row-text-bannerP) {
        padding: 0.3rem 0 !important;
    }

    .titulo-h1-banner {
        font-size: 2.8rem !important;
    }

    .carousel-caption {
    }

    .titulo-buscador-banner {
        font-size: 2.6rem;
    }

    .lado-izquierdo-porque-lc {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }

    .titulo-porque-lc {
        font-size: 1.45rem;
    }

    .container-testimonios .texto-titulo-testimonio3 {
        font-size: 2rem;
    }

    .container-testimonios .texto-titulo-testimonio2 {
        font-size: 1.2rem;
    }

    .container-testimonios .texto-titulo-testimonio1 {
        font-size: 1.3rem;
    }

    .container-testimonios .icon-inside-globo {
        max-width: 3.5rem;
        margin-left: -61px;
        margin-top: 11px;
    }

    .img-testominio {
        height: 60px;
        width: 60px;
    }

    .nombre_testimonio {
        font-size: 1.3rem;
    }

    .contenid-not .ps-4 {
        padding-left: 0.5rem !important;
    }

    .titulos-principal-testimonio {
        right: 80px;
        top: 85px;
    }

    .container-testimonios .lado-izquierdo-testimonios {
        height: 630px;
    }
}


/*********      991     **********/

@media (max-width: 991px) {
    .imgtrian {
        width: 80px;
        top: -60px !important;
    }

    .future {
        top: -71px;
    }

    .top-pequeno {
        padding: 1em 0.9375em 0 8em;
    }

    div.noticia div.footer-boton {
        bottom: 20px !important;
    }

    #espacioBlanco {
        height: 100px;
    }

    .container-subtitulo {
        width: 750px;
    }

    .menu-usuario li a {
        font-size: 10px !important;
    }

    .menu-usuario li.dropdown-header {
        padding: 3px 5px !important;
    }

    #menu-usuario .dropdown-menu {
        min-width: 200px !important;
    }

    #menu-usuario div.texto-gris {
        padding-left: 0 !important;
    }

    #search li a {
        white-space: normal !important;
    }

    .nav2 .navbar-header {
        float: none;
    }

    #bs-content-row-navbar-collapse-5 .navbar-right {
        float: none !important;
    }

    .nav2 .navbar-toggle,
    .navbar-header .navbar-toggle {
        display: block;
    }

    div.barra-azul button.navbar-toggle {
        padding: 8px !important;
        border-radius: 50%;
        transform: translateY(-50%);
        position: relative;
        top: 50%;
        right: 5%;
        background-color: transparent !important;
        opacity: 1 !important;
    }

    .nav2 .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    }

    .nav2 .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }

    .navbar-header navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }

    .nav2 .navbar-collapse.collapse,
    .navbar-header .navbar-collapse.collapse {
        display: none !important;
    }

    .nav2 .navbar-nav {
        float: none !important;
        margin-top: 7.5px;
    }

    .nav2 .navbar-nav > li,
    #id_menuheaderP li {
        float: none;
    }

    .nav2 .navbar-nav > li > a {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .nav2 .collapse.in,
    .navbar-header .collapse.in {
        display: block !important;
    }

    .menu-der {
        transform: translateY(0) !important;
        top: 0px !important;
        right: 0 !important;
        border: none;
    }

    .margen {
        margin-left: 0px !important;
        position: absolute;
        background: #FFFFFF;
        padding-bottom: 0 !important;
        padding-left: 0 !important;
        z-index: 2;
        min-height: 0 !important;
        margin-left: 2px;
        right: -11px;
    }

    .menudos {
        top: -31px;;
        width: 280px;
    }

    #previaCarpeta {
        right: -115px !important;
    }

    #EliminarPrevCarpeta .fa-2x {
        font-size: 18px !important;
    }

    .contenedores {
        padding-left: 2% !important;
        padding-right: 2% !important;
    }

    .subTitulos {
        font-size: 12pt !important;
    }

    .titulo_noticias {
        font-size: 19px !important;
    }

    .descripcionEstudia {
        font-size: 12pt !important;
    }

    .padding {
        padding: 10px 10px !important;
        height: 186px !important;
        overflow: auto;
    }

    .contenedores img {
        height: 330px !important;
    }

    .seccion-descripcion,
    .seccion-descripcion-derecha {
        height: 340px !important;
    }

    .fa-2x {
        font-size: 1.5em !important;
    }

    .cont-testimonio,
    .front-testimonio,
    .back-testimonio {
        height: 280px;
    }

    .front-testimonio {
        padding: 70px 25px 5px;
    }

    .back-testimonio {
        padding: 45px 25px 5px 30px;
    }

    .back-testimonio .descripcion_testimonio {
        height: 150px !important;
    }

    .titulo-oficinas-home h2,
    .titulo-testimonio h2 {
        margin-top: 30px !important;
    }

    .logo_horizontal {
        left: 5%;
        width: 125px;
    }

    .menu-table-movil {
        float: right;
        width: 210px;
        position: absolute;
        right: -1px;
        top: 66px;
        background-color: #fff;
        border-radius: 4px;
    }

    #id_menuheaderRedes {
        margin-right: 5%;
    }

    .navbar-default .navbar-toggle:focus,
    .navbar-default .navbar-toggle:hover,
    .navbar-default .navbar-toggle:visited {
        opacity: 1 !important;
    }

    #search {
        text-align: left !important;
    }

    #search.nav > li > a {
        padding: 0.8rem 1.2rem .8rem 1.6rem !important;
        font-size: 1.3rem;
    }

    #search li {
        width: 100%;
    }

    #search li:nth-child(1) ul li:nth-child(2) {
        display: inline-block !important;
    }

    #search li:nth-child(1) {
        display: inline-block !important;
    }

    #search li ul li:last-child {
        display: block !important;
    }

    .dropdown-menu > li > a {
        padding: 7px 20px;
    }

    #notificacion_li {
    }

    /* REGISTRO USUARIO */
    .carpeta-listado-curso #carpeta {
        width: 25px;
        position: absolute;
        top: 10px !important;
    }

    .nav-azul {
        min-height: 85px !important;
    }

    .barra-azul {
        height: 85px !important;
    }

    .texto-pathways {
        font-size: 1.15rem;
        width: 100%;
    }

    .login-movil {
        display: inline-block !important;
    }

    .botonera-wrap {
        bottom: 0;
        width: auto;
    }

    .titulo-h1-banner {
        font-size: 2.5rem !important;
    }

    .subtitulo-h1-banner {

    }

    .a-enlace-banner {
        padding-left: 0;
        margin: 1rem 0 2rem;
    }

    #bannersEscritorio .boton-banner-promo {
        margin-left: 0;
    }

    #headerMenu {
        height: 85px;
    }

    .logo_lc {
        left: 20px;
        width: 110px;
    }

    .titulo-buscador-banner {
        font-size: 2.2rem;
    }

    .subtitulo-buscador-banner {
        font-size: 1.2rem;
    }

    .contenedor-select .row {
        margin-top: 1.3rem !important;
    }

    .container-fomulario-buscador {
        padding-top: 1.5rem !important;
    }

    .contenedor-buscador-banner .row-banner-home {
        height: 235px;
    }

    .marge-buscador select {
        height: 40px;
    }

    .marge-buscador .select2-container--default .select2-selection--single {
        height: 40px;
    }

    .row-banner-home {
        height: 90px;
    }

    .row-banner-home .py-5 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }

    .container-porque-lc {
        margin-top: 3rem !important;
        margin-bottom: 1rem !important;
    }

    .titulo-porque-lc {
        font-size: 2rem;
    }

    .container-porque-lc .embed-container {
        min-height: 400px !important;
        max-height: 400px !important;
        display: block;
    }

    .titulos-principal-testimonio {
        right: 0;
        top: 30px;
    }

    .container-testimonios .icon-inside-globo {
        max-width: 3.5rem;
        margin-left: -60px;
        margin-top: 12px;
    }

    .container-fluid-review {
        margin-top: 5rem !important;
    }

    .review {
    }

    .container-testimonios .texto-titulo-testimonio3 {
        font-size: 1.8rem;
    }

    .container-testimonios .texto-titulo-testimonio2 {
        font-size: 1.8rem;
        font-family: Rubik-Bold !important;
    }

    .container-porque-lc .embed-container .video {
        transform: initial !important;
    }

    .container-porque-elegirnos .row {
        justify-content: center !important;
    }

    .container-principal-porque-elegirnos {
        /*background: url(/static/img/home/banner-396x515.jpg);*/
        background: transparent;
        background-position: top;
        background-size: cover;
        height: 550px;
        background-repeat: no-repeat;
        margin-bottom: -1px;
    }

    .py-8 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }

    .fila-principal {
        padding-left: 0px !important;
    }

    .boton-cita {
        text-align: center;
    }

    #carpeta {
        width: 32px;
    }

    #notificacion_contador {
        padding: 2px 7px 3px 5px !important;
    }

    .carousel-caption {
        top: 18%;
    }

    #bannersEscritorio,
    #bannersEscritorio .carousel-inner,
    #bannersEscritorio .carousel-inner .carousel-item,
    #bannersEscritorio .embed-container,
    #bannersEscritorio .contenidoBannes {
        min-height: 470px !important;
        max-height: 470px !important;
    }

    .btn-suscribir {
        margin-top: 1rem;
    }
    .descripcion-elegisrnos, .titulo-elegisrnos2{
        color: #080C38 !important;
    }
}


/*********      900     **********/

@media (max-width: 900px) {
    .imgtrian {
        width: 70px;
    }

    .future {
        top: -80px;
    }

    .mundo-ofic img {
        width: 413px;
    }

    .banners .carousel-inner,
    .banners,
    .banners .carousel-inner .item,
    .video-banner {
        min-height: 330px !important;
        max-height: 330px !important;
        height: 330px !important;
    }

    .img_banners {
        min-height: inherit !important;
        max-height: 600px !important;
    }

    .content-buscador-banner {
        bottom: 213px;
    }

    .content-buscador-banner .campo-buscador {
        padding: 8px 10px 8px !important;
    }

    .content-buscador-banner .campo-buscar {
        padding: 8px 10px 9px 10px !important;
    }

    .triangulo-rigth,
    .triangulo-left {
        margin-top: -49px;
        height: 50px;
    }

    .btn-default {
        font-size: 1rem !important;
    }

    #franja-verde {
        background-size: 100% 100%;
        background-position: center;
    }

    #divNoticias .noticia {
        min-height: 200px !important;
        height: 200px !important;
    }

    .titularSm {
        font-size: 15px !important;
    }

    .subtitulo_noticia p {
        font-size: 13px !important;
    }

    table.display td {
        padding: 5px 0 !important;
    }

    #texto-franja-verde {
        font-size: 12px !important;
    }

    .contTestimonio .descripcion_testimonio,
    .contTestimonio .paisTestimonio {
        font-size: 11px !important;
    }

    .caption {
        text-align: center;
    }

    .img_scroll {
        left: 50px !important;
    }

    .imgtrian {
        top: -65px !important;
    }

    .img_scroll {
        top: -23px !important;
    }

    .subtit {
        font-size: 9pt !important;
    }

    .botonera-categorias .categoria_cuadros {
        padding: 6px;
    }

    .carpeta-listado-curso #carpeta {
        width: 25px;
        position: absolute;
        top: 10px !important;
    }

    .texto-pathways {
        font-size: 1.15rem;
        width: 100%;
    }

    .titulos-h1 {
        font-size: 1.6em;
    }

    .titulos_principales {
        font-size: 1.6em;
    }

    .tit {
        width: 300px !important;
        font-size: 1.3em !important;
        padding: 15px !important;
        left: 14%;
    }

    .enlaces-rapidos {
        font-size: .6em;
    }

    #buscar-cursos-prgramas {
        font-size: 1.2rem !important;
    }
}


/*********      879     **********/

@media (max-width: 879px) {
    .mundo-ofic img {
        width: 413px;
    }

    #ulRedesFooter li {
        height: 20px !important;
        width: 30px !important;
    }

    .cont-testimonio,
    .front-testimonio,
    .back-testimonio {
        height: 260px;
        width: 100%;
    }

    .front-testimonio {
        padding: 85px 40px 5px;
    }

    #testimoniales {
        padding: 10px 50px 40px;
    }

    .back-testimonio {
        padding: 60px 40px 5px 50px;
    }

    .paisTestimonio {
        font-size: 15px;
    }

    .back-testimonio .descripcion_testimonio {
        height: 185px !important;
    }

    #testimoniales .indicadores {
        top: -200px !important;
    }

    .imgtrian {
        width: 70px;
    }

    .future {
        top: -80px;
    }

    .cont-cat {
        margin: 20px 0 65px 0;
    }

    .botonera-categorias .cursos {
        height: 113px;
        width: 100%;
    }

    .botonera-categorias .cursos img {
        width: 40px;
    }

    .titulo-boton-categoria {
        font-size: 12px;
    }

    .titulos-h1 {
        font-size: 1.5em;
    }

    .titulos_principales {
        font-size: 1.5em;
    }

    .texto-pathways {
        font-size: 1.15rem;
    }

    .titulo-h1-banner {
        font-size: 2.4rem !important;
    }

    .a-enlace-banner {
        padding-left: 40px;
    }

    .pathways-title {
        font-size: 1.6rem !important;
    }

    .img-verde-video {
        max-width: 90%;
        right: -18px;
        top: -47px;
    }
}


/*********      768     **********/

@media (max-width: 768px) {
    .titulo-h1-banner {
        font-size: 2.2rem;
    }
    .mundo-ofic img {
        width: 335px;
    }

    .btn-default {
        font-size: 12px !important;
    }

    #ulRedesFooter i {
        font-size: 1.8em !important;
    }

    .logo-lc-footer {
        width: 100px !important;
    }

    .paises .tituloP {
        font-size: 16pt !important;
    }

    .sub-titular {
        font-size: 12pt !important;
    }

    .future {
        top: -70px !important;
    }

    #divNoticias .noticia {
        min-height: 145px !important;
        height: 145px !important;
    }

    .margen {
        margin-left: 0;
    }

    .imgtrian {
        top: -60px !important;
        width: 85px !important;
    }

    .top-alto {
        padding-top: 80px !important;
    }

    #franja-verde {
        height: 42px !important;
    }

    #texto-franja-verde {
        font-size: 9px !important;
    }

    .link_icon {
        float: right !important;
    }

    #links li a {
        float: right !important;
    }

    #search > li > a {
        padding-left: 4px;
        padding-right: 4px;
    }

    .titularSm {
        font-size: 12px !important;
        margin-bottom: 10px !important;
    }

    .subtitulo_noticia p {
        font-size: 11px !important;
    }

    .btn-leermas {
        width: 120px !important;
        font-size: 10px !important;
    }

    .caption {
        text-align: center;
    }

    #divNoticias .footer-boton {
        bottom: 13px !important;
    }

    .carousel-control .glyphicon-chevron-right,
    .carousel-control .icon-next {
        margin-right: -30px;
    }

    .carousel-control .glyphicon-chevron-left,
    .carousel-control .icon-prev {
        margin-left: -30px;
    }

    .bootstrap-select .text {
        font-size: 11px !important;
    }

    #id_menuheaderP2 {
        right: 0 !important;
    }

    #id_menuheaderP2 .inconosRedes {
        font-size: 1em !important;
    }

    #input-buscar {
        left: 80px !important;
    }

    .banners .carousel-inner,
    .banners,
    .banners .carousel-inner .item,
    .video-banner {
        min-height: 350px !important;
        max-height: 350px !important;
        height: 350px !important;
    }

    .img_banners {
        min-height: inherit !important;
        max-height: 550px !important;
    }

    .contenidoBannes .subtit {
        margin: 6px auto;
    }

    .banners .carousel-control .glyphicon-chevron-left,
    .banners .carousel-control .glyphicon-chevron-right {
        top: 48%;
    }

    .content-buscador-banner {
        bottom: 214px;
    }

    .content-buscador-banner .campo-buscador {
        padding: 6px;
    }

    .content-buscador-banner .campo-buscar {
        padding: 6px 6px !important;
    }

    .triangulo-rigth,
    .triangulo-left {
        margin-top: -44px;
        height: 45px;
        width: 54%;
    }

    .noticia .subtitulo_noticia {
        font-size: 13px !important;
    }

    .img_scroll {
        top: -44px !important;
    }

    .log768 {
        width: 80px !important;
        position: relative;
        top: -16px;
    }

    .carousel-indicators .active {
        width: 9px !important;;
        height: 9px !important;
    }

    #VideoB {
        width: 25px !important;
    }

    .contendorCarpeta a {
        width: 100px !important;
        font-size: 11px !important;
    }

    .bannersAzul {
        min-height: 250px !important;
        height: 250px !important;
    }

    .img-log {
        max-height: 40px;
        min-height: 40px;
    }

    .cont-bandera {
        width: 90%;
    }

    .pequenos {
        width: 48.6% !important;
    }

    .altos {
        width: 48.6% !important;
    }

    .gris {
        height: inherit !important;
    }

    .titulo-boton-categoria {
        font-size: 10px;
    }

    .botonera-categorias .cursos {
        height: 113px;
        width: 100%;
    }

    .logo-nuevo {
        right: -4px;
        width: 35px !important;
    }

    .cont-testimonio,
    .front-testimonio,
    .back-testimonio {
        height: 312px;
    }

    .contentImgTestimonio {
        height: 312px;
    }

    .front-testimonio {
        padding: 80px 30px 5px;
    }

    #testimoniales .indicadores {
        top: -190px !important;
    }

    .back-testimonio {
        padding: 50px 30px 5px 40px;
    }

    .paisTestimonio {
        font-size: 13px;
    }

    .back-testimonio .descripcion_testimonio {
        height: 170px !important;
    }

    .content-comillas {
        width: 75px;
        height: 75px;
    }

    .content-comillas img {
        width: 45px !important;
    }

    #preguntas-frecuentes .accordion .plus-icon {
        margin-top: -12px !important;
        margin-right: -20px;
    }

    #id_menuheaderRedes {
        float: right;
    }

    /*  REGISTRO DE USUARIO */
    .cont-form a {
        font-size: 11pt !important;
    }

    #vistaLista .bandera-pais {
        right: 10px !important;
    }

    .titulos-h1 {
        font-size: 1.4em;
    }

    .titulos_principales {
        font-size: 1.4em;
    }

    .texto-pathways {
        font-size: 1rem;
        width: 100%;
    }

    .cont-pais-ofic {
        width: 100px;
        height: 100px;
        padding: 17px 20px 20px 20px;
    }

    #paisesOficinas img {
        width: 40px;
    }

    #paisesOficinas a {
        font-size: 9pt;
    }

    .tit {
        height: 300px;
    }

    #bannersEscritorio,
    #bannersEscritorio .carousel-inner,
    #bannersEscritorio .carousel-inner .carousel-item,
    #bannersEscritorio .embed-container,
    #bannersEscritorio .contenidoBannes {
        min-height: 430px !important;
        max-height: 430px !important;
    }

    .titulo-h1-banner {
        font-size: 2.2rem !important;
    }
.subtitulo-h1-banner, .subtitulo-boton {
        font-size: 1.2rem;
    }
    #bannersEscritorio .container {
        padding: 0 !important;
    }

    .contenedor-titulo-promocion {
        padding: 0 !important;
    }

    .titulo-bannerP {
        font-size: 1.2rem;
    }

    .container-testimonios .icon-inside-globo {
        max-width: 6rem;
        margin-left: -60px;
        margin-top: 12px;
    }

    .review {
        margin-bottom: 20px;
        height: 130px;
    }

    .review-user {
        font-size: 2.8rem;
    }

    .review-google,
    .review-faceboock {
        font-size: 3rem;
    }

    .incono-mas {
        font-size: 1.7rem;
        top: 22px;
        margin-right: 0;
        left: 0;
    }

    .texto-review-user {
        font-size: .95rem;
    }

    .texto-review-google,
    .texto-review-faceboock {
        font-size: .95rem;
    }

    .container-porque-lc .embed-container {
        min-height: 400px !important;
        max-height: 400px !important;
    }

    .titulo-porque-lc {
        font-size: 2rem;
    }

    .img-testominio {
        height: 80px;
        width: 80px;
        margin: 0 auto;
    }

    .subtitulo-h1-banner {

    }

    .carousel-caption {
        top: 10%;
    }
}


/*********      767     **********/

@media only screen and (max-width: 767px) {
    .mt-buscador {
        margin-top: 4rem !important;
    }
    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        color: #414042 !important;
        font-weight: bold;
    }

    .mundo-ofic img {
        width: 283px;
    }

    .botonera-categorias .cursos {
        height: 115px;
        width: 115px;
    }

    .botonera-categorias .cursos img {
        width: 55px;
    }

    .titulo-boton-categoria {
        font-size: 14px;
    }

    .container-subtitulo {
        width: 90% !important;
    }

    #search {
        height: auto !important;
        overflow: visible !important;
    }

    #search .open .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        float: left;
        background-color: #fff;
        border: 1px solid #ccc;
        box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    }

    #id_menuheaderP .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        color: #fff !important;
    }

    #id_menuheaderP {
        padding: 10px !important;
        margin: 0 !important;
    }

    #id_menuheaderP li.dropdown.open {
        margin-top: 5px !important;
    }

    #bs-content-row-navbar-collapse-5 {
        padding-left: 0 !important;
    }

    ul.pies {
        margin: 7.5px 5px !important;
    }

    .banners .carousel-inner,
    .banners,
    .banners .carousel-inner .item,
    .video-banner {
        min-height: 400px !important;
        max-height: 400px !important;
        height: 400px !important;
        top: 32px;
    }

    /*#bannersMovil {
        margin-top: 79px;
    }*/
    #headerMenu {
        height: 80px;
    }

    .banners .carousel-inner .item {
        background-size: cover !important;
    }

    .banners .carousel-control .glyphicon-chevron-left {
        margin-left: -20px !important;
    }

    .banners .carousel-control .glyphicon-chevron-right {
        margin-right: -20px !important;
    }

    .content-buscador-banner .campo-buscador,
    .content-buscador-banner .campo-buscar {
        margin-left: -1px;
        margin-top: -1px;
        padding: 5px 6px !important;
    }

    .content-buscador-banner .campo-buscar {
        padding: 5px !important;
    }

    #porque-lc-tv .botonera-categorias {
        width: 210px;
        margin-top: 0 !important;
    }

    #id_menuheaderRedes {
        float: right;
    }

    .navbar-collapse {
        border-top: none;
    }

    #noticias .carousel-inner,
    #noticias .carousel-inner .item {
        height: 170px !important;
    }

    #noticias .subtitulo_noticia {
        text-align: left;
        color: #FFFFFF;
    }

    .titulo-oficinas-home h2,
    .titulo-testimonio h2 {
        margin: 20px 0 0 !important;
    }

    .log768 {
        width: 70px !important;
        top: -19px;
        left: 4em;
    }

    .logo_horizontal {
        left: 1%;
        width: 120px;
    }

    .barra-azul,
    .nav-azul {
        height: 80px !important;
        min-height: 80px;
    }

    .menu-table-movil {
        top: 55px;
    }

    .botonera-categorias {
        width: 36%;
    }

    .bootstrap-select button {
        padding: 8.5px 12px !important;
        height: 45px;
    }

    .contenedorForm {
        margin-right: 10%;
        margin-left: 10%;
        margin-top: 8rem;
        margin-bottom: 6rem;
    }

    .si-noticia {
        padding-left: 10px !important;
    }

    .si-suscripcion {
        padding-left: 5px !important;
    }

    .contenedorTitulo span {
        font-size: 1.7rem;
    }

    .navbar {
        border: none;
    }

    #pager2 .visible-xs,
    #pager .visible-xs {
        display: inline !important;
    }

    .carpeta-listado-curso #carpeta {
        width: 25px;
        position: relative !important;
        top: 10px !important;
    }

    .texto-pathways {
        font-size: 1rem;
        width: 100%;
    }

    .educ-dist {
        border-radius: 0 !important;
    }

    .tit {
        width: 100% !important;
        left: initial !important;
        height: inherit !important;
        opacity: .8;
        padding: 40px 288px 15px 60px !important;
        background: transparent;
        top: -18px;
        font-size: 2.5rem !important;
        text-shadow: 1px 1px 6px rgb(78 78 90 / 78%);
    }

    .a-enlace-banner {
        padding-left: 60px;
    }

    .como-movil {
        position: absolute;
        bottom: 0;
        text-align: center;
        width: 100%;
        padding: 7px 5px;
        font-size: 1.3rem;
        z-index: 1;
    }

    .carousel-caption {
        z-index: 0;
        bottom: 100px;
    }

    .como-movil #botones-movil {
        list-style: none;
        padding: 0;
        display: inline-block;
        margin-bottom: 0;
    }

    .como-movil #botones-movil li {
        float: left;
        width: 90px;
        height: 80px;
        box-shadow: 1px 5px 9px 2px #a5a5a540;
        border-radius: 4px;
        margin: 4px;
        padding: 6px;
        background: #fff;
    }

    .a-enlace-movil {
        width: 100%;
        text-decoration: underline;
    }

    .enlaces-rapidos-movil {
        width: 100%;
        float: left;
    }

    .enlaces-rapidos-movil img {
        margin: 3px auto;
    }

    .content-buscador-home .campo-buscador {
        padding-right: 5px !important;
        padding-left: 5px !important;
    }

    .titulo-buscador-home {
        font-size: 1.45em;
        text-align: center;
    }

    .content-buscador-home #formulario {
        padding: 15px;
        margin: 20px auto;
    }

    .cot-video-canada {
        margin-bottom: 20% !important;
    }

    .cont-testimonio,
    .front-testimonio,
    .back-testimonio {
        height: 600px;
        border-radius: 0 !important;
    }

    #testimoniales .indicadores {
        top: -291px !important;
    }

    .ver-mas-paises-movil {
        margin: 20px;
    }

    .btn-default {
        font-size: 1rem !important;
    }

    .embed-container {
        height: 230px;
    }

    #testimoniales .thumbnail {
        border-radius: 0 !important;
    }

    .contentImgTestimonio {
        border-radius: 0 !important;
    }

    .nav-azul {
        position: fixed;
        top: 0;
    }

    .paypal-button-container {
        width: 70%;
    }

    .pathways-title {
        font-size: 2.5rem !important;
        text-align: center;
        margin-bottom: 53px;
    }

    .rowBannerP {
        display: inherit;
    }

    .col-text-bannerP > .row:not(.row-text-bannerP) > div {
        text-align: center;
    }

    .titulo-bannerP {
        font-size: 1.5rem;
    }

    .subtituloP {
        font-size: 1.1rem;
    }

    .subtitulo-pc {
        display: none;
    }

    .subtitulo-movil {
        display: block;
    }

    .boton-banner-promo {
        font-size: 1.1rem;
    }

    .subtitulo-buscador-banner {
        font-size: 1.5rem;
        margin: 0;
        margin-top: 10px !important;
    }

    .titulo-buscador-banner {
        font-size: 2.2rem;
    }

    .row-banner-home {
        background: url(/static/img/home/fondo_verde_lc_767.jpg);
        background-repeat: no-repeat;
        height: 270px;
    }

    .id_categorias {
        padding-left: 10px !important;
    }

    .buscar {
        padding-right: 10px !important;
    }

    .container-paises {
        background-position: top;
    }

    .contenido-noticias {
        margin-bottom: 10px;
    }

    .container-testimonios .texto-titulo-testimonio1 {
        font-size: 1.3rem;
    }

    .container-testimonios .texto-titulo-testimonio2 {
        font-size: 1.8rem;
    }

    .container-testimonios .texto-titulo-testimonio3 {
        font-size: 1.8rem;
    }

    .review-faceboock,
    .review-google,
    .review-user {
        font-size: 4rem !important;
    }

    .incono-mas {
        top: 30px;
        left: 41px;
        font-size: 2rem;
    }

    .texto-review-user {
        font-size: 1.2rem;
        position: relative;
        bottom: 15px;
    }

    .texto-review-google,
    .texto-review-faceboock {
        font-size: 1.2rem;
        bottom: 15px;
    }

    .container-porque-lc .embed-container {
        min-height: 300px !important;
        max-height: 300px !important;
    }

    .titulo-porque-lc {
        font-size: 1.55rem;
    }

    .titulo-elegisrnos1 {
        font-size: 1.2rem;
    }

    .titulo-elegisrnos2 {
        font-size: 1.5rem;
    }

    .titulo-elegisrnos3 {
        font-size: 2rem;
    }

    .descripcion-elegisrnos {
        font-size: 1.1rem;
    }

    .header-verde {
        min-height: 70px;
        position: fixed;
        top: 0;
    }

    .row-buscar {
        margin-top: 20% !important;
    }

    .principal-event {
        margin-top: 3rem !important;
    }
    .subtitulo-h1-banner, .subtitulo-boton {
        font-size: 1.2rem;
    }
    .titulo-destino{
        text-align: center;

    }
}


/*********      667     **********/

@media (max-width: 667px) {
    /*.container-subtitulo {
        width: 660px !important;
    }*/
    .titulos_principales {
        font-size: 1.6em;
    }

    .margen {
        margin-left: 0px;
    }

    .log768 {
        width: 60px !important;
        position: relative;
        top: 1px;
    }

    #logoNavBlanco {
        top: 20px !important;
        left: 2em !important;
    }

    #previaCarpeta {
        width: 290px !important;
    }

    div.barra-azul button.navbar-toggle {
        padding: 8px !important;
        border-radius: 50%;
    }

    .menu-der {
        background: #080C38;
        float: right;
        transform: none;
        top: 3px;
        border-color: #080C38 !important;
    }

    #VideoB {
        width: 25px;
    }

    .img_banners {
        min-height: inherit !important;
        max-height: 170px !important;
    }

    .img_bannersdetalle {
        height: auto !important;
    }

    .gl-star-rating[data-star-rating] {
        position: relative;
        left: 4px;
    }

    .subtitulo_noticia {
        color: #FFFFFF !important;
    }

    .titularMovil {
        color: #FFFFFF !important;
        font-size: 15px !important;
    }

    .titulocat,
    .categ {
        left: 0 !important;
        text-align: center;
    }

    #bs-content-row-navbar-collapse-5 {
        width: 40% !important;
        right: -50px !important;
    }

    .rect-testimonio {
        width: 30px;
        height: 120px;
        position: absolute;
    }

    .content-comillas img {
        width: 35px !important;
    }

    .content-comillas {
        width: 65px;
        height: 65px;
        margin-top: 10px;
    }

    .back-testimonio {
        padding: 45px 20px 5px 25px;
    }

    .back-testimonio .descripcion_testimonio {
        font-size: 11px !important;
        height: 150px !important;
    }

    .back-testimonio .paisTestimonio {
        padding: 0 40px 0 0;
    }

    .paisTestimonio {
        font-size: 12px;
    }

    .front-testimonio {
        padding: 70px 20px 5px;
    }

    #testimoniales .indicadores {
        top: -315px !important;
    }

    .content-buscador-banner {
        bottom: 125px !important;
        position: relative;
    }

    .barra-azul,
    .nav-azul {
        height: 80px !important;
        min-height: 80px;
    }

    .logo_horizontal {
        left: 0%;
        width: 120px;
    }

    .botonera-categorias .cursos {
        height: 120px;
        width: 120px;
    }

    .botonera-categorias {
        width: 44%;
    }

    .logo-nuevo {
        width: 40px !important;
    }

    .titulos-h1 {
        font-size: 1.6em;
    }

    .titulo-asesoria {
        font-size: 1.5em;
    }

    .titulos_principales {
        font-size: 18pt !important;
    }

    .paypal-button-container {
        width: 100%;
    }

    .buscador-banner .select2-container--default .select2-selection--single .select2-selection__rendered {
        padding: 0 !important;
    }

    .pathways-title {
        font-size: 2rem !important;
    }

    .titulo-porque-lc {
        font-size: 1.7rem;
    }

    .container-porque-lc .embed-container {
        min-height: 300px !important;
        max-height: 300px !important;
    }

    .row-buscar {
        margin-top: 25% !important;
    }
    .titulo-h1-banner {
        font-size: 1.8rem !important;
    }
}


/*********      640     **********/

@media (max-width: 640px) {
    /*.container-subtitulo {
        width: 610px !important;
    }*/
    .botonera-categorias .cursos {
        height: 120px;
        width: 120px;
    }

    .botonera-categorias {
        width: 44%;
    }

    .subtit {
        font-size: 11pt;
    }

    #VideoB {
        width: 32px;
    }

    #logoNavBlanco {
        top: 19px !important;
        left: 1em !important;
    }
}


/*********      599     **********/

@media only screen and (max-width: 576px) {
    .seccion-descripcion {
        margin-left: 0;
    }

    /*.container-subtitulo {
        width: 550px !important;
    }*/
    #logoNavBlanco {
        top: 19px !important;
        left: 1em !important;
    }

    .cont-testimonio,
    .front-testimonio,
    .back-testimonio {
        height: 480px;
    }

    #testimoniales {
        padding: 10px 120px 40px;
    }

    .front-testimonio {
        padding: 80px 30px 5px;
    }

    #testimoniales .indicadores {
        top: -262px !important;
    }

    .back-testimonio {
        padding: 50px 30px 5px 40px;
    }

    .back-testimonio .descripcion_testimonio {
        height: 180px !important;
    }

    .paisTestimonio {
        font-size: 13px;
    }

    h3 {
        margin-top: 15px;
    }

    #search li {
        display: block !important;
        text-align: left;
    }

    .menu-table-movil {
        float: right;
        width: 205px;
        position: absolute;
        right: -1px;
        top: 54px;
        background-color: #fff;
        border-radius: 4px;
    }

    .botonera-categorias {
        width: 53%;
    }

    .contentImgTestimonio {
        height: 210px;
    }

    .tit {
        padding: 0 40px !important;
    }

    #bannersMovil,
    #bannersMovil .carousel-inner,
    #bannersMovil .carousel-inner .carousel-item,
    #bannersMovil .contenidoBannes {
        min-height: 300px !important;
        max-height: 300px !important;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .carousel-caption {
        top: 12%;
    }


    .tit {
        top: 0;
        font-size: 2.2rem !important;
    }

    .titulo-h1-banner {
        font-size: 2.4rem !important;
    }

    .container-banner-promocional {
        padding: 2.5rem 2.5rem 3rem 1.8rem;
    }

    .logo_lc {
        left: 20px;
        width: 100px;
    }

    .hamburger-movil {
        right: 10px;
        top: 5px;
    }

    .header-verde {
        min-height: 65px;
        position: fixed;
    }

    .titulo-buscador-banner {
        font-size: 1.8rem;
    }

    .subtitulo-buscador-banner {
        font-size: 1.2rem;
    }

    .titulo-porque-lc {
        font-size: 1.6rem;
    }

    .img-testominio {
        height: 70px;
        width: 70px;
    }

    #carousel-noticias .ps-4 {
        padding-left: 0 !important;
    }

    .container-testimonios .texto-titulo-testimonio2 {
        font-size: 1.7rem;
    }

    .container-testimonios .texto-titulo-testimonio3 {
        font-size: 1.7rem;
    }

    .container-testimonios .texto-titulo-testimonio1 {
        font-size: 1.3rem;
    }

    .nombre_testimonio {
        font-size: 1.4rem;
    }

    .container-porque-lc .embed-container {
        min-height: 290px !important;
        max-height: 290px !important;
        margin-top: 15px;
    }

    .container-porque-lc .embed-container .video {
        height: 100%;
        width: 100%;
    }

    .footer-titulos h6 {
        font-size: .9rem;
    }

    #footer .ulNavegacionPies > li > a {
        font-size: .8rem;
    }

    .logo-lc-footer {
        width: 80px !important;
    }

    #ulRedesFooter li {
        height: 25px !important;
        width: 30px !important;
    }

    #ulRedesFooter svg,
    #ulRedesFooter i {
        font-size: 1.5rem !important;
    }

    .container-paises {
        background: url(/static/img/home/fondo_mapa575.png);
        background-position: top;
    }

    #notificacion_contador {
        right: 0;
        top: 0px;
    }

    .container-menu-movil {
        top: 65px;
    }

    .container-newsletter {
        padding: 0 !important;
    }

    .container-newsletter2 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .titulos-principal-testimonio{
            padding: 0rem 1rem 1rem 1rem !important;
    }
    .container-porque-lc {
        margin-top: 2rem !important;
        margin-bottom: 0rem !important;
    }
    .py-8 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
    .container-principal-porque-elegirnos{
        height: 500px!important;
    }
}


/*********      540     **********/

@media only screen and (max-width: 540px) {
    /*.container-subtitulo {
        width: 550px !important;
    }*/
    #testimoniales {
        padding: 10px 100px 40px;
    }

    .botonera-categorias {
        width: 66% !important;
    }

    .col-text-bannerP > .row > div {
        text-align: center;
        padding: 5px 10px !important;
    }

    .titulo-bannerP {
        text-align: left;
    }

    .col-text-bannerP > .row > div:first-child {
        padding: 0 5px 5px !important;
    }

    /*.icon-globo-text {
        max-width: 90% !important;
    }*/
    .content-banner-promocion {
        padding: 3.5rem 0;
        margin-bottom: 8%;
    }

    .titulo-bannerP {
        font-size: 1.3rem;
    }

    .img-testominio {
        height: 70px;
        width: 70px;
    }
}

@media only screen and (max-width: 500px) {
    .botonera-categorias {
        width: 66% !important;
    }

    .cont-form a {
        font-size: 11pt !important;
    }

    .contenedorTitulo span {
        font-size: 1.4em;
    }

    .si-noticia {
        padding-left: 21px !important;
    }

    .si-suscripcion {
        padding-left: 5px !important;
    }

    .titulos-h1 {
        font-size: 1.5em;
    }

    .titulos_principales {
        font-size: 1.5em;
    }

    .titulo-asesoria {
        font-size: 1.4em;
    }

    .botonera-pasos .titulo-boton-categoria {
        font-size: 11px;
    }

    .tit {
        padding: 20px 174px 15px 40px !important;
        margin: 0 !important;
    }

    .a-enlace-banner {
        padding-left: 40px;
        margin: 0 0 1rem;
    }

    .enlaces-rapidos {
        font-size: .7em;
    }

    #noticias .owl-carousel .owl-stage-outer {
        padding: 20px 2px;
    }

    .contenid-not {
        padding: 0 !important;
    }

    .container-testimonios .texto-titulo-testimonio3 {
        font-size: 1.7rem;
    }

    .incono-mas {
        left: 35px;
    }

    .review-faceboock,
    .review-google,
    .review-user {
        font-size: 3.5rem !important;
    }

    .texto-review-user {
        font-size: 1.1rem;
    }

    .texto-review-google,
    .texto-review-faceboock {
        font-size: 1rem;
        bottom: 15px;
    }
}


/*********      480     **********/

@media only screen and (max-width: 480px) {
    #logoNavBlanco {
        top: 20px !important;
        left: 0 !important;
    }

    .log768 {
        left: 2em;
        width: 65px !important;
    }

    #textoCabecera {
        font-size: 12px !important;
    }

    .cont-cat {
        margin-bottom: 30px !important;
    }

    .banners .carousel-inner,
    .banners,
    .banners .carousel-inner .item,
    .video-banner {
        min-height: 300px !important;
        max-height: 300px !important;
        height: 300px !important;
    }

    .banners .carousel-control .glyphicon-chevron-left,
    .banners .carousel-control .glyphicon-chevron-right {
        top: 44%;
    }

    .tit {
        line-height: 1.3;
        height: 300px;
    }

    #previaCarpeta {
        width: 240px !important;
    }

    .logo-cursoCarp {
        height: 45px !important;
    }

    .banners .carousel-control .glyphicon-chevron-left {
        margin-left: -10px !important;
    }

    .banners .carousel-control .glyphicon-chevron-right {
        margin-right: -10px !important;
    }

    #noticias .carousel-control .glyphicon-chevron-left {
        margin-left: -20px;
    }

    #testimoniales {
        padding: 10px 70px 40px;
    }

    h3 {
        margin-top: 15px;
    }

    .botonera-categorias .cursos {
        height: 100px;
        width: 100px;
    }

    .botonera-categorias .cursos img {
        width: 40px !important;
    }

    .titulo-boton-categoria {
        font-size: 11px;
    }

    .content-datos {
        padding: 15px !important;
    }

    #id_menuheaderRedes,
    #id_menuheaderRedes li a {
        width: 70px;
        padding: 10px 8px;
    }

    .logo_horizontal {
        width: 95px;
        left: 1%;
    }

    .barra-azul,
    .nav-azul {
        height: 60px !important;
        min-height: 60px !important;
    }

    .menu-table-movil {
        float: right;
        width: 230px;
        position: absolute;
        right: 0;
        top: 44px;
        background-color: #fff;
        border-radius: 4px;
    }

    .content-buscador-banner {
        bottom: 100px !important;
        position: relative;
    }

    #buscar-cursos-prgramas {
        font-size: 1.3rem !important;
        padding: 5px 12px;
    }

    .subtitulo-pag {
        font-size: 11px !important;
    }

    #seo-h1-h2 {
        padding: 0;
    }

    .botonera-categorias {
        width: 60%;
    }

    .contenedorForm {
        margin-right: 10%;
        margin-left: 10%;
    }

    .cont-testimonio,
    .front-testimonio,
    .back-testimonio {
        height: 480px;
    }

    /*#bannersMovil {
        margin-top: 60px;
    }*/
    #headerMenu {
        height: 60px;
    }

    .tit {
        font-size: 2.3rem
    }

    .pathways-title {
        font-size: 1.7rem !important;
    }

    .img-verde-video {
        top: -34px;
        max-width: 100%;
    }

    .cot-video-canada {
        padding-left: 0 !important;
    }

    .container-principal-porque-elegirnos {
        height: 550px !important;
    }
    .titulo-h1-banner {
        font-size: 2rem !important;
    }
}

@media (max-width: 450px) {
    .container-testimonios .texto-titulo-testimonio2 {
        font-size: 1.5rem;
    }

    .container-testimonios .texto-titulo-testimonio3 {
        font-size: 1.5rem;
    }

    .nombre_testimonio {
        font-size: 1.2rem;
    }
}


/*********      425     **********/

@media (max-width: 425px) {
    .contenedorTitulo span {
        font-size: 1.3em;
    }

    #cantidad-cotizaciones {
        font-size: 18px !important;
    }

    .content-datos {
        padding: 15px !important;
    }

    .texto-modal-paso3 {
        padding: 23px 0 !important;
        margin: 0 0px !important;
    }

    .h4-compara {
        font-size: 14px !important;
    }

    h3 {
        margin-top: 15px;
    }

    .container content-buscador-banner > .row {
        padding: 5px !important;
    }

    /*.container-subtitulo {
        width: 425px !important;
    }*/
    #search {
        margin-left: 0% !important;
    }

    .pies li > a {
        padding-left: 14px !important;
    }

    .nav-azul {
        min-height: 45px !important;
    }

    .top-pequeno {
        padding-top: 54px !important;
    }

    #textoCabecera {
        font-size: 11px !important;
    }

    div.barra-azul button.navbar-toggle {
        padding: 8px !important;
    }

    .log768 {
        width: 70px !important;
    }

    .logo-lc-footer {
        width: 80px !important;
    }

    .titularMovil {
        color: #FFFFFF !important;
        font-size: 15px !important;
    }

    #input-buscar {
        left: 80px !important;
    }

    .nroTab {
        font-size: 28px;
    }

    .subTitulos {
        font-size: 9pt !important;
    }

    .izquierdaF {
        left: 5px !important;
    }

    .derechaF {
        right: 5px !important;
    }

    .carousel-control.right {
        right: 5px !important;
    }

    .descripcion-pla-int {
        height: 208px !important;
    }

    .seccion-descripcion {
        margin-left: 0 !important;
    }

    .menu-der {
        transform: translateY(0) !important;
        top: 0px !important;
        right: 0 !important;
        border: none;
    }

    #id_menuheaderP a {
        margin-left: 0px !important;
    }

    #id_menuheaderP2 {
        right: 0px !important;
    }

    #paisesOficinas div {
        font-size: 9pt;
    }

    .tit i {
        margin-top: 10px !important;
    }

    .barra-azul {
        height: 46px !important;
    }

    .thumbnail .caption {
        width: 100% !important;
        text-align: left;
    }

    .iconos-categorias {
        margin-right: 8px !important;
        width: 30px !important;
    }

    .tit {
        margin-top: 0px !important;
        line-height: 1.3;
    }

    .subtit {
        font-size: 5pt !important;
        margin: 7px auto !important;
    }

    #franja-verde {
        background-image: url(../img/cintillo_gris_movil.png) !important;
        background-repeat: no-repeat;
        height: 35px !important;
        background-size: inherit !important;
    }

    #texto-franja-verde {
        font-size: 8pt !important;
    }

    #search > li > a {
        padding: 7px 10px !important;
    }

    #menu-usuario .img-responsive {
        margin-top: -3px !important;
    }

    #id_menuheaderP li > a {
        color: #FFFFFF !important;
        padding: 5px 15px 5px 10px !important;
        font-size: 12px !important;
    }

    .RedesSociales1 a {
        font-size: 12px !important;
    }

    #id_menuheaderP .verde {
        background-color: transparent !important;
        text-align: left !important;
    }

    .menu-usuario {
        padding: 0 !important;
    }

    .content-buscador-banner .campo-buscar {
        padding: 5px 6px 5px !important;
    }

    #VideoB {
        width: 25px !important;
    }

    #divNoticias {
        margin-top: 0 !important;
    }

    .titulos_principales {
        font-size: 1.5em;
    }

    .contenido-princ {
        margin: 0 !important;
    }

    .sub-titular {
        font-size: 9pt !important;
    }

    .sub-titular p {
        font-size: 11pt !important;
    }

    #menuUsuario {
        width: 100% !important;
    }

    #MenuResponsiveUsuario .navbar-default {
        background-color: transparent !important;
        border-color: transparent !important;
        width: 100% !important;
    }

    #MenuResponsiveUsuario .navbar {
        min-height: auto !important;
        margin-bottom: 0px !important;;
    }

    .contendorDos {
        margin-top: 0 !important;
    }

    .contendorCarpeta a {
        width: 136px !important;
    }

    .contendorDos h1 {
        font-size: 22pt !important;
        text-align: center !important;
        margin: 0 !important;
    }

    #footer .col-xs-6 {
        padding-right: 0 !important;
    }

    .liseparador {
        display: block !important;
    }

    .descripcion_testimonio {
        font-size: 11px !important;
    }

    .seccion-descripcion,
    .seccion-descripcion-derecha {
        height: auto !important;
    }

    .pie-future {
        width: 44% !important;
    }

    .pie-ult {
        font-size: 11pt !important;
    }

    .img_bannersdetalle {
        height: 170px !important;
    }

    .respon {
        text-align: justify !important;
        padding: 0 27px !important;
    }

    .magenBottom {
        margin-bottom: 10px;
    }

    .gl-star-rating[data-star-rating] {
        position: relative;
        left: 4px;
    }

    .paises .tituloP {
        font-size: 18pt !important;
    }

    .navbar-header {
        width: 100% !important;
    }

    #bs-content-row-navbar-collapse-5 {
        width: 50%;
        top: 46px !important;
        right: -52px !important;
    }

    .imgAgrad {
        height: 367px !important;
    }

    #galeria_principal .carousel-inner {
        height: 250px !important;
    }

    .indicador {
        margin-top: 8% !important;
    }

    .triangulo-rigth,
    .triangulo-left {
        width: 56%;
        margin-top: -39px;
        height: 40px;
    }

    .content-buscador-banner .form-control,
    .content-buscador-banner .campo-buscador button,
    .content-buscador-banner .campo-buscar input {
        height: 35px !important;
        font-size: 11px !important;
    }

    .content-comillas {
        width: 60px;
        height: 60px;
    }

    .content-comillas img {
        width: 30px !important;
    }

    .rect-testimonio {
        width: 20px;
        height: 100px;
    }

    .rect-testimonio.izq {
        margin-top: -120px;
    }

    .rect-testimonio.der {
        margin-top: -30px;
    }

    .cont-testimonio,
    .front-testimonio,
    .back-testimonio {
        height: 480px;
    }

    #testimoniales {
        padding: 10px 50px 40px;
    }

    #testimoniales .indicadores {
        top: -272px !important;
    }

    .front-testimonio {
        padding: 75px 25px 5px;
    }

    .back-testimonio {
        padding: 45px 25px 5px 35px;
    }

    .back-testimonio .descripcion_testimonio {
        height: 150px !important;
    }

    .menu-table-movil {
        top: 48px;
    }

    .titulos-h1 {
        font-size: 1.45em;
    }

    .texto-pathways {
        font-size: 1rem;
        width: 100%;
    }

    #bannersMovil,
    #bannersMovil .carousel-inner,
    #bannersMovil .carousel-inner .carousel-item,
    #bannersMovil .contenidoBannes {
        min-height: 300px !important;
        max-height: 300px !important;
    }

    .titulo-h1-banner {
        font-size: 1.65rem !important;
    }

    .titulo-bannerP {
        font-size: 1.2rem;
    }

    .subtituloP {
        font-size: 1rem;
    }

    .titulo-porque-lc {
        font-size: 1.5rem;
    }

    .container-paises .texto-titulo-destino {
        font-size: 2.2rem;
    }

    .container-paises .texto-titulo-destino2 {
        font-size: 2.7rem;
    }

    .container-testimonios .texto-titulo-testimonio3 {
        font-size: 1.5rem;
    }

    .container-testimonios .texto-titulo-testimonio2 {
        font-size: 1.5rem;
    }

    .container-testimonios .texto-titulo-testimonio1 {
        font-size: 1.3rem;
    }

    .nombre_testimonio {
        font-size: 1.1rem;
        padding-left: 20px;
    }

    .pais-testimonio {
        padding-left: 20px;
    }

    .titulo-destino {
        font-size: 2.5rem;
    }

    .titulo-elegisrnos1 {
        font-size: 1rem;
    }

    .titulo-elegisrnos2 {
        font-size: 1.25rem;
    }

    .titulo-elegisrnos3 {
        font-size: 1.8rem;
    }

    .descripcion-elegisrnos {
        font-size: 1rem;
    }

    #footer .ulNavegacionPies > li > a {
        font-size: .8rem;
    }

    .footer-titulos h6 {
        font-size: .85rem;
    }

    .logo-previa-carpeta {
        width: 35px;
    }

    .cinta-black-friday {
        font-size: .8rem !important;
    }
    .banner-sub-title{
        font-size: 1rem !important;
    }
     .container-porque-lc .embed-container {
        min-height: 200px !important;
        max-height: 200px !important;
    }
     .container-principal-porque-elegirnos {
        height: 500px !important;
    }
}


/*********      414     **********/

@media (max-width: 414px) {
    h3 {
        margin-top: 15px;
    }

    /*.container-subtitulo {
        width: 414px !important;
    }*/
    .tamano {
        width: 160px !important;
        font-size: 18px !important;
    }

    .gris {
        height: inherit !important;
    }

    .descripcion {
        padding: 0 !important;
    }

    #titulo-agradecimientoVerde,
    #titulo-agradecimientoAzul {
        font-size: 19pt !important;
    }

    .margen {
        margin-left: 0;
    }

    .top-alto {
        padding-top: 0;
    }

    .titulocat,
    .categ {
        left: 0 !important;
        text-align: center;
    }

    .paises .tituloP {
        font-size: 18pt !important;
    }

    .log768 {
        width: 60px !important;
        position: relative;
        top: -4px;
    }

    #espacioBlanco {
        height: 55px !important;
    }

    #logoNavBlanco {
        top: 15px !important;
    }

    #preguntas-frecuentes .plus-icon {
        margin-top: -12px !important;
    }

    .texto-modal-paso3 {
        padding: 23px 0 !important;
        margin: 0 0px !important;
    }
}

@media (max-width: 411px) {
    .container-subtitulo {
        width: 95% !important;
    }
}

@media (max-width: 384px) {
    .botonera-categorias .cursos {
        height: 100px;
        width: 100px;
    }

    .contenedorTitulo span {
        font-size: 1.6em;
    }

    .botonera-categorias .cursos img {
        width: 40px !important;
    }

    .titulo-boton-categoria {
        font-size: 11px;
    }

    h3 {
        margin-top: 15px;
    }

    .content-buscador-banner {
        bottom: 122px !important;
    }

    .si-noticia {
        padding-left: 21px !important;
    }

    .si-suscripcion {
        padding-left: 5px !important;
    }

    /*.container-subtitulo {
        width: 384px !important;
    }

    #preguntas-frecuentes .accordion-panel .plus-icon{
        top: 85% !important;
    }
    */
    #preguntas-frecuentes .accordion-panel .accordion dt {
        font-size: 12px !important;
    }

    .thumbnail .caption {
        width: 100% !important;
        text-align: left;
    }

    .seccion-descripcion,
    .seccion-descripcion-derecha {
        height: auto !important;
    }

    .navbar-header {
        width: 100% !important;
    }

    .carousel-indicators .active {
        width: 9px !important;;
        height: 9px !important;
    }

    #VideoB {
        width: 20px !important;
    }

    .titularMovil,
    .subtitulo_noticia {
        font-size: 9pt !important;
        color: #FFFFFF !important;
        font-family: Montserrat-Bold;
    }

    .subtitulo_noticia p {
        font-size: 7pt !important;
    }

    .btn-leermas {
        width: 100px !important;
        font-size: 9px !important;
    }

    .botones {
        margin-right: 190px !important;
    }

    .gris {
        height: initial !important;
    }

    .descEvent {
        min-height: 140px !important;
    }

    .descEvent1 {
        min-height: 70px !important;
    }

    .subtit {
        font-size: 5pt !important;
        margin: 5px auto !important;
    }

    #franja-verde {
        background-image: url(../img/cintillo_gris_movil.png) !important;
        background-repeat: no-repeat;
        height: 35px !important;
        background-size: inherit !important;
    }

    #texto-franja-verde {
        font-size: 8pt !important;
    }

    #oficinas-paises .accordion-panel {
        margin: 10px 10px !important;
    }

    .botonera-categorias {
        width: 240px;
        margin-top: 0px;
    }

    #porque-lc-tv .botonera-categorias {
        width: 210px;
        margin-top: 0 !important;
    }

    .banners .carousel-inner,
    .banners,
    .banners .carousel-inner .item,
    .video-banner {
        min-height: 300px !important;
        max-height: 300px !important;
        height: 300px !important;
    }

    .content-buscador-banner .campo-buscador,
    .content-buscador-banner .campo-buscar {
        padding: 5px 6px 3px !important;
    }

    .content-buscador-banner .form-control,
    .content-buscador-banner .campo-buscador button,
    .content-buscador-banner .campo-buscar input {
        height: 35px !important;
    }

    .content-buscador-banner .campo-buscador button,
    .content-buscador-banner .campo-buscar input {
        padding: 5px 12px;
    }

    .botonera-categorias .categoria_cuadros {
        padding: 7px 10px;
    }

    .triangulo-rigth,
    .triangulo-left {
        height: 41px;
        width: 58%;
    }

    .texto-modal-paso3 {
        padding: 23px 0 !important;
        margin: 0 0px !important;
    }

    #seo-h1-h2 li {
        font-size: 9px;
    }

    .subtitulo-pag {
        font-size: 9px !important;
    }

    #seo-h1-h2 li {
        padding-left: 10px;
        padding-right: 10px;
    }

    .tit {
        padding: 20px 120px 15px 30px !important;
        top: 0px;
    }

    .a-enlace-banner {
        padding-left: 30px;
        margin: 0 0 1rem;
    }

    .pathways-title {
        font-size: 1.5rem !important;
    }

    .titulo-bannerP {
        font-size: 1.1rem;
    }

    .card-noticias {
        width: 100% !important;
    }

    .titulo-destino {
        font-size: 2.5rem;
    }

    .container-paises .texto-titulo-destino2 {
        font-size: 2.5rem;
    }

    .container-paises .texto-titulo-destino {
        font-size: 2rem;
    }

    .container-all-testimonials .px-5 {
        padding-right: 1.2rem !important;
        padding-left: 1.2rem !important;
    }

    .container-testimonios .icon-inside-globo {
        max-width: 3rem;
        margin-left: -50px;
        margin-top: 7px;
    }

    .container-testimonios .texto-titulo-testimonio3 {
        font-size: 1.4rem;
    }

    .container-testimonios .texto-titulo-testimonio2 {
        font-size: 1.4rem;
    }

    .container-testimonios .texto-titulo-testimonio1 {
        line-height: 1.6;
    }

    .container-testimonios .icon-inside-globo {
        max-width: 6rem;
        margin-left: -57px;
        margin-top: 8px;
    }

    #notificacion_contador {
        margin-left: 10px !important;
        padding: 2px 7px 3px 5px !important;
        top: -5px;
    }

    .row-buscar {
        margin-top: 30% !important;
    }
}


/*********      375        **********/

@media only screen and (max-width: 375px) {
    /*.container-subtitulo {
        width: 375px !important;
    }*/
    .contenedorTitulo span {
        font-size: 1.2em;
    }

    .subtitulo_noticia p {
        margin: 0px 0 5px !important;
    }

    .thumbnail .caption {
        text-align: left;
    }

    .imgAgrad {
        height: 200px !important;
    }

    .categorias .carousel-inner {
        height: 180px !important;
    }

    #galeria_principal .carousel-inner {
        height: 200px !important;
    }

    .indicador {
        margin-top: 6% !important;
    }

    .paises .tituloP {
        font-size: 20pt !important;
    }

    .margen {
        margin-left: 0;
    }

    .titulocat,
    .categ {
        left: 0 !important;
        text-align: center;
    }

    .descripcion {
        padding: 0;
    }

    #titulo-agradecimientoVerde,
    #titulo-agradecimientoAzul {
        font-size: 14pt !important;
    }

    .descripcion {
        padding: 0 !important;
    }

    .gl-star-rating[data-star-rating] {
        position: relative;
        left: 40px;
    }

    .subtit {
        font-size: 10pt;
        margin: 20px auto !important;
    }

    .carousel-indicators .active {
        width: 9px !important;;
        height: 9px !important;
    }

    #VideoB {
        width: 20px;
    }

    .img_banners {
        min-height: 142px !important;
        max-height: 142px !important;
    }

    .img_bannersdetalle {
        height: 170px !important;
    }

    .gl-star-rating[data-star-rating] {
        position: relative;
        left: 4px;
    }

    .pequenos {
        width: 49% !important;
    }

    .altos {
        width: 49% !important;
    }

    /**
    .gris {
        height: 439px !important;
    }
    **/
    .descEvent {
        min-height: 130px !important;
    }

    .margen {
        margin-left: 0 !important;
    }

    #logoNavBlanco {
        top: 10px !important;
    }

    div.barra-azul button.navbar-toggle {
        padding: 6px;
        border-radius: 50%;
    }

    #espacioBlanco {
        height: 60px !important;
    }

    .top-pequeno {
        padding-top: 0 !important;
    }

    .tamano {
        width: 160px !important;
        font-size: 18px !important;
    }

    .thumbnail {
        padding: 0 !important;
    }

    .row-tipo-clases .thumbnail {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .top-alto {
        padding-top: 0;
    }

    .subtit {
        font-size: 7pt !important;
        margin: 10px auto !important;
    }

    #franja-verde {
        background-image: url(../img/cintillo_gris_movil.png) !important;
        background-repeat: no-repeat;
        height: 35px !important;
        background-size: inherit !important;
    }

    #texto-franja-verde {
        font-size: 7pt !important;
    }

    .menudos {
        top: -9px !important;
    }

    #search li a {
        white-space: normal !important;
    }

    .a-logo-reponsive {
        top: -10px !important;
    }

    #testimoniales {
        padding: 10px 35px 40px;
    }

    #testimoniales .thumbnail {
        margin-bottom: 0 !important;
    }

    #testimoniales .indicadores {
        top: -254px !important;
    }

    .rect-testimonio.izq {
        margin-top: -100px;
    }

    .cont-educ-intern {
        margin: 0 auto !important;
        text-align: center;
        width: 74%;
    }

    #textoCabecera {
        width: 70%;
    }

    #bannersMovil,
    #bannersMovil .carousel-inner,
    #bannersMovil .carousel-inner .carousel-item,
    #bannersMovil .contenidoBannes {
        min-height: 256px !important;
        max-height: 256px !important;
    }

    .titulo-h1-banner {
        font-size: 1.5rem !important;
    }
.banner-sub-title{
    font-size: 1rem !important;
}
    .titulo-porque-lc {
        font-size: 1.4rem;
    }


    .container-testimonios .texto-titulo-testimonio2 {
        font-size: 1.3rem;
    }

    .container-testimonios .texto-titulo-testimonio3 {
        font-size: 1.3rem;
    }

    .descripcion-testimonios div {
        padding: 0 12px;
    }

    .incono-mas {
        left: 10px;
    }
}

@media (max-width: 360px) {
    /*.container-subtitulo {
        width: 360px !important;
    }*/
    .botonera-categorias .cursos {
        height: 100px;
        width: 100px;
    }

    .botonera-categorias .cursos img {
        width: 40px !important;
    }

    .titulo-boton-categoria {
        font-size: 11px;
    }

    .titulos_principales {
        font-size: 1.3em;
    }

    .titulo-asesoria {
        font-size: 1.2em;
    }

    .titulos-h1 {
        font-size: 1.2em;
    }

    img.rect-testimonio.der {
        margin-top: -95px;
        width: 40px;
    }

    img.rect-testimonio.izq {
        margin-top: -38px;
        width: 40px;
    }

    #noticias .caption .descEvent {
        font-size: 11px;
        margin-top: 10px;
    }

    #noticias .thumbnail {
        height: 520px;
    }

    .pies li > a {
        padding-left: 14px !important;
    }

    .contTestimonio .descripcion_testimonio,
    .contTestimonio .paisTestimonio {
        font-size: 11px !important;
    }

    #previaCarpeta {
        width: 210px !important;
    }

    .logo-cursoCarp {
        height: 40px !important;
    }

    #EliminarPrevCarpeta .fa-2x {
        font-size: 15px !important;
    }

    #previaCarpeta #filtrar {
        width: 80px !important;
        font-size: 10px !important;
        margin: 5px 0 !important;
        padding: 5px !important;
    }

    .tit {
        font-size: 2.2rem !important;
    }

    .banners .carousel-inner,
    .banners,
    .banners .carousel-inner .item,
    .video-banner {
        min-height: 300px !important;
        max-height: 300px !important;
        height: 300px !important;
    }

    .log768 {
        top: 0;
    }

    .cont-educ-intern {
        margin: 0 auto !important;
        text-align: center;
        width: 74%;
    }

    #textoCabecera {
        width: 74%;
    }

    .img-verde-video {
        top: -27px;
        max-width: 100%;
    }

    .titulo-bannerP {
        font-size: 1rem;
    }

    .subtituloP {
        font-size: .95rem;
    }

    .container-testimonios .icon-inside-globo {
        max-width: 3rem;
        margin-left: -52px;
        margin-top: 8px;
    }

    .texto-review-user {
        font-size: 1rem;
        margin-top: 8px;
    }

    .review-faceboock,
    .review-google,
    .review-user {
        font-size: 3rem !important;
        margin-top: 20px;
    }

    .incono-mas {
        left: 18px;
        top: 20px;
    }

    .previa-carpeta-movil {
        /*right: -124px !important;*/
    }

    .container-principal-porque-elegirnos {
        height: 620px;
    }
    .container-principal-porque-elegirnos {
        height: 580px !important;
    }
    .container-principal-porque-elegirnos {
        height: 580px !important;
    }
}


/*************    320     *************/

@media (max-width: 320px) {
    .contenedorTitulo span {
        font-size: 1.2em;
    }

    .si-noticia {
        padding-left: 21px !important;
    }

    .si-suscripcion {
        padding-left: 5px !important;
    }

    .cont-form a {
        font-size: 10pt !important;
    }

    .contenedorForm {
        margin-right: 5%;
        margin-left: 5%;
    }

    .cont-pais-ofic {
        width: 100px;
        height: 100px;
        padding: 15px;
    }

    /*.container-subtitulo {
        width: 320px !important;
    }*/
    .subtitulo-pag {
        font-size: 12px !important;
    }

    .pies li > a {
        padding-left: 14px !important;
    }

    .titularMovil,
    .subtitulo_noticia {
        font-size: 9pt !important;
        color: #FFFFFF !important;
    }

    #noticias .btn-leermas {
        width: 100px !important;
        font-size: 9px !important;
    }

    #fRegistro h5 {
        font-size: 12px !important;
    }

    .nroTab {
        font-size: 25px;
    }

    .titulos_principales {
        font-size: 1.2em;
    }

    .titulo-asesoria {
        font-size: 1em;
    }

    .text-porquelc {
        font-size: 0.9em !important;
    }

    .content-datos {
        padding: 10px !important;
    }

    .footer-titulos p {
        font-size: .8rem !important;
    }

    .logo-lc-footer {
        width: 70px !important;
    }

    #footer .navbar-nav > li > a {
        padding-bottom: 11px;
    }

    .a-logo-reponsive {
        float: left;
        position: relative;
        top: 1px;
    }

    .iconos-categorias {
        margin-right: 5px !important;
        width: 35px !important;
    }

    .iconos-categorias {
        width: 24px !important;
    }

    .flatpickr-calendar {
        right: 15px !important;
    }

    .paises .tituloP {
        font-size: 18pt !important;
    }

    .log768 {
        width: 55px !important;
    }

    .triangulo-rigth,
    .triangulo-left {
        width: 60%;
        margin-top: -28px;
        height: 30px;
    }

    .subtit {
        font-size: 5pt !important;
        margin: 0 auto !important;
    }

    #oficinas-paises .accordion-panel {
        margin: 10px 10px !important;
    }

    #franja-verde {
        background-image: url(../img/cintillo_gris_movil.png) !important;
        background-repeat: no-repeat;
        height: 35px !important;
        background-size: inherit !important;
    }

    #texto-franja-verde {
        font-size: 8pt !important;
    }

    #logoNavBlanco {
        top: 23px !important;
    }

    #espacioBlanco {
        height: 55px !important;
    }

    #id_menuheaderP li > a,
    .RedesSociales1 a {
        font-size: 10px !important;
    }

    .content-buscador-banner .form-control,
    .content-buscador-banner .campo-buscador button,
    .content-buscador-banner .campo-buscar input {
        height: 35px !important;
        font-size: 10px !important;
    }

    .content-buscador-banner .campo-buscador,
    .content-buscador-banner .campo-buscar {
        padding: 3px 4px 3px !important;
    }

    .banners .carousel-inner,
    .banners,
    .banners .carousel-inner .item,
    .video-banner {
        min-height: 250px !important;
        max-height: 250px !important;
        height: 250px !important;
    }

    #testimoniales {
        padding: 10px 30px 40px;
    }

    .front-testimonio {
        padding: 65px 15px 5px;
    }

    .paisTestimonio {
        font-size: 12px;
    }

    .back-testimonio {
        padding: 40px 15px 5px 25px;
    }

    .back-testimonio .descripcion_testimonio {
        height: 135px !important;
    }

    #testimoniales .indicadores {
        top: -254px !important;
    }

    .rect-testimonio {
        width: 15px;
        height: 90px;
    }

    .texto-modal-paso3 {
        padding: 23px 0 !important;
        margin: 0 0px !important;
    }

    .h4-compara {
        font-size: 12px !important;
    }

    .cont-educ-intern {
        margin: 0 auto !important;
        text-align: center;
        width: 74%;
    }

    #textoCabecera {
        width: 74%;
    }

    .logo-nuevo {
        right: -19px;
        width: 35px !important;
    }

    .pagination > li > a,
    .pagination > li > span {
        font-size: 10px !important;
    }

    .texto-pathways {
        font-size: 1rem;
        width: 100%;
    }

    .titulos-h1 {
        font-size: 1.08em;
    }

    .tit {
        height: 250px;
    }

    .btn-default {
        font-size: .85rem !important;
    }

    .tit {
        font-size: 2rem !important;
    }

    .titilos-pawey {
        padding: 0 !important;
    }

    .pathways-title {
        font-size: 1.4rem !important;
    }

    .titulo-h1-banner {
        font-size: 1.3rem !important;
    }

    .titulo-bannerP {
        font-size: .85rem;
    }

    .subtituloP {
        font-size: .85rem;
    }

    .logo_lc {
        right: 0;
        left: 0;
        width: 90px;
    }

    .header-verde .navbar-default .navbar-nav > li > a,
    .header-verde .nav-link {
        font-size: .9rem !important;
    }

    .header-verde {
        min-height: 70px;
    }

    .titulo-porque-lc {
        font-size: 1.2rem;
    }

    .card-destinos {
        width: 16rem !important;
    }

    .container-testimonios .icon-inside-globo {
        width: 37px;
        margin-left: -41px;
        margin-top: 6px;
    }

    .container-testimonios .texto-titulo-testimonio2 {
        font-size: 1rem;
    }

    .container-testimonios .texto-titulo-testimonio3 {
        font-size: 1rem;
    }

    .nombre_testimonio {
        font-size: .98rem;
    }

    .img-testominio {
        height: 50px;
        width: 50px;
    }

    .incono-mas {
        left: 8px;
        top: 18px;
    }

    .footer-titulos h6 {
        font-size: .8rem;
    }

    .hamburger-movil {
        right: 0;
        top: 5px;
    }

    #id_menuheader_movil {
        right: 0px !important;
    }

    .cinta-black-friday {
        font-size: .8rem !important;
    }

    .container-principal-porque-elegirnos {
        height: 655px;
    }
}